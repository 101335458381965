import { useState } from "react";
import { useIntl } from "react-intl";
import PresalePasswordInput from "@ticketingplatform/ui/dist/components/molecules/PresalePasswordInput";
import EventDetailsSection from "@ticketingplatform/ui/dist/components/sections/EventDetailsSection";
import EventHeading from "@ticketingplatform/ui/dist/components/molecules/EventHeading";
import Header from "@ticketingplatform/ui/dist/components/organisms/Header";
import useNavItems from "hooks/useNavItems";
import ScrollToTop from "components/ScrollToTop";
import SessionInfoBar from "components/SessionInfoBar";
import useEventHeading from "hooks/useEventHeading";
import useSessionInfoBar from "hooks/useSessionInfoBar";
import useSummaryBar from "hooks/useSummaryBar";

import { useEventDetails, useEventTickets } from "./hooks";
import * as Styles from "./styles";

const EventDetailsPage: React.FC = () => {
  const [presalePassword, setPresalePassword] = useState<string | undefined>(
    undefined
  );
  const { formatMessage } = useIntl();
  const navItems = useNavItems();
  const eventDetails = useEventDetails();
  const eventTickets = useEventTickets(presalePassword);
  const eventHeading = useEventHeading();
  const summaryBar = useSummaryBar();
  const sessionInfoBar = useSessionInfoBar();

  return (
    <>
      <ScrollToTop />
      <Header href="/" items={navItems} withCloseIcon closeHref="/" />
      {!presalePassword &&
      eventDetails?.presale &&
      eventDetails?.presale.length > 0 ? (
        <Styles.PasswordScreenContainer>
          <EventHeading {...eventHeading} />
          <PresalePasswordInput
            passwords={eventDetails.presale}
            presalePassword={presalePassword}
            setPresalePassword={setPresalePassword}
          />
        </Styles.PasswordScreenContainer>
      ) : (
        <EventDetailsSection
          onSaleLabel={formatMessage({
            defaultMessage: "On Sale",
            description: "On Sale label",
          })}
          submitLabel={formatMessage({
            defaultMessage: "Next",
            description: "Summary bar button label label",
          })}
          {...eventDetails}
          {...eventTickets}
          {...{ eventHeading, summaryBar }}
        />
      )}
      {sessionInfoBar && <SessionInfoBar {...sessionInfoBar} />}
    </>
  );
};

export default EventDetailsPage;
