import styled, { css } from "styled-components";
import media from "theme/config/media";

const commonWysiwyg = css`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 1rem;

  a {
    color: #1d80fa;
    text-decoration: underline;
  }

  h1 {
    font-size: 3rem;
    margin-bottom: 0.5rem;
  }
  h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  em {
    font-style: italic;
  }
  ol {
    margin-bottom: 0.5rem;
    list-style-type: decimal;
    padding-left: 1.5rem;
  }

  ul {
    list-style-type: disc;
    padding-left: 1.5rem;
    margin-bottom: 0.5rem;
  }

  strong {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }

  p {
    margin-bottom: 0.25rem;
  }

  a {
    transition: ${({ theme }) => theme.transition};
  }

  @media ${media.tablet} {
    a:hover {
      color: ${({ theme }) => theme.colors.tiara};
    }
  }
`;

export const WysiwygParagraph = styled.p`
  ${commonWysiwyg}
`;

export const WysiwygContainer = styled.div`
  ${commonWysiwyg}
`;
