import { gql } from "@apollo/client";

import { EVENT_FRAGMENT } from "./fragments/event";

export const CREATE_EVENT = gql`
  ${EVENT_FRAGMENT}
  mutation CreateEvent($input: EventCreateInput!) {
    eventCreate(input: $input) {
      ...Event
    }
  }
`;

export const UPDATE_EVENT = gql`
  ${EVENT_FRAGMENT}
  mutation UpdateEvent($id: Int!, $input: EventUpdateInput!) {
    eventUpdate(id: $id, input: $input) {
      ...Event
    }
  }
`;

export const DELETE_EVENT = gql`
  ${EVENT_FRAGMENT}
  mutation DeleteEvent($id: String!) {
    eventDelete(id: $id) {
      ...Event
    }
  }
`;
