import { useVenueListQuery } from "@ticketingplatform/api/dist/venue";
import { useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { SortDirection } from "@ticketingplatform/api/dist/__generated__/globalTypes";

import { UseVenuePickerProps, UseVenuePickerReturnvalue } from "./types";

const useVenuePicker = <IsMulti extends boolean = false>({
  isMulti,
  idIn,
  firstAsDefaultValue,
  firstAsDefaultValueIfSingleOption,
}: UseVenuePickerProps<IsMulti> = {}): UseVenuePickerReturnvalue<IsMulti> => {
  const intl = useIntl();
  const [value, onChange] =
    useState<UseVenuePickerReturnvalue<IsMulti>["value"]>();
  const { data, loading, error } = useVenueListQuery({
    variables: {
      organizationInclude: false,
      addressInclude: false,
      take: -1,
      orderBy: [{ name: SortDirection.asc }],
      filters: {
        id: {
          in: idIn,
        },
      },
    },
  });

  return useMemo(() => {
    const options = data?.venues?.nodes || [];

    const defaultValue = (isMulti
      ? options.slice(0, 1)
      : options[0]) as unknown as UseVenuePickerReturnvalue<IsMulti>["value"];

    return {
      value:
        value ||
        (firstAsDefaultValue ||
        (firstAsDefaultValueIfSingleOption && options.length === 1)
          ? defaultValue
          : undefined),
      onChange,
      isMulti,
      options,
      isLoading: loading,
      getOptionLabel: ({ name }) => name,
      error:
        error &&
        intl.formatMessage({ defaultMessage: "Failed to load Venues" }),
    };
  }, [
    data?.venues?.nodes,
    error,
    firstAsDefaultValue,
    firstAsDefaultValueIfSingleOption,
    intl,
    isMulti,
    loading,
    value,
  ]);
};

export default useVenuePicker;
