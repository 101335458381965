import Checkout from "..";

import * as Styled from "./styles";
import { SuccessMessageProps } from "./types";

const SuccessSection: React.FC<SuccessMessageProps> = ({
  eventHeading,
  successMessage,
}) => (
  <Checkout {...{ eventHeading }}>
    {typeof successMessage === "string" ? (
      <Styled.SuccessMessage variant="typo1">
        {successMessage}
      </Styled.SuccessMessage>
    ) : (
      <Styled.SuccessMessage as="div" variant="typo1">
        {successMessage}
      </Styled.SuccessMessage>
    )}
  </Checkout>
);

export default SuccessSection;
