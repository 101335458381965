import { Field } from "react-final-form";

import { PhoneNumberInputFieldProps } from "./types";

import PhoneNumberInput from ".";

const PhoneNumberInputField: React.FC<PhoneNumberInputFieldProps> = ({
  name,
  ...props
}) => (
  <Field
    {...{ name }}
    render={({
      input,
      meta: { touched, modifiedSinceLastSubmit, error, submitError },
    }) => (
      <PhoneNumberInput
        error={touched && !modifiedSinceLastSubmit && (error || submitError)}
        {...input}
        {...props}
      />
    )}
  />
);

export default PhoneNumberInputField;
