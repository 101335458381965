import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useOrderContext } from "providers/OrderProvider/hooks";
import environment from "setup/environment";
import useEventOccurrence from "hooks/useEventOccurrence";
import { areFeesIncluded } from "utils/priceDisplay";

import { UseSummaryBar } from "./types";

const useSummaryBar: UseSummaryBar = ({ withFees } = {}) => {
  const intl = useIntl();
  const { order, error } = useOrderContext();
  const { eventOccurrence } = useEventOccurrence();

  const withFeesFromVenue = areFeesIncluded(
    eventOccurrence?.event.venue?.priceDisplay
  );

  return useMemo(
    () => ({
      quantityLabel: intl.formatMessage({
        defaultMessage: "QTY",
        description: "Tickets quantity label",
      }),
      buttonLabel: intl.formatMessage({
        defaultMessage: "Checkout",
        description: "Summary bar button label label",
      }),
      totalLabel: intl.formatMessage({
        defaultMessage: "Total",
        description: "Total label",
      }),
      disabled: !order?.tickets?.nodes.length || !!error,
      quantity: order?.tickets?.nodes.length || 0,
      total:
        withFeesFromVenue || withFees
          ? intl.formatNumber(order?.totalAmount || 0, {
              style: "currency",
              currency: environment.CURRENCY,
            })
          : intl.formatMessage(
              { defaultMessage: "{value} + Fees" },
              {
                value: intl.formatNumber(order?.subtotalAmount || 0, {
                  style: "currency",
                  currency: environment.CURRENCY,
                }),
              }
            ),
    }),
    [
      intl,
      order?.subtotalAmount,
      order?.tickets?.nodes.length,
      order?.totalAmount,
      withFees,
      withFeesFromVenue,
      error,
    ]
  );
};

export default useSummaryBar;
