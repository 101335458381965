import React from "react";

import * as Styled from "./styles";
import { TextProps } from "./types";

const Text = React.forwardRef<HTMLElement, TextProps>(
  ({ children, variant = "text", as: asProp, ...rest }, ref) => {
    const as = asProp || "p";

    return (
      <Styled.Text {...{ variant, as, ref, ...rest }}>{children}</Styled.Text>
    );
  }
);

export default Text;
