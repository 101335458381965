import { gql } from "@apollo/client";

import {
  DISCOUNT_CODE_FRAGMENT,
  DISCOUNT_CODE_NODE_FRAGMENT,
} from "./fragments";

export const DISCOUNT_CODE_CREATE_MUTATION = gql`
  ${DISCOUNT_CODE_FRAGMENT}
  mutation DiscountCodeCreate($input: DiscountCodeCreateInput!) {
    discountCodeCreate(input: $input) {
      ...DiscountCode
    }
  }
`;

export const DISCOUNT_CODE_UPDATE_MUTATION = gql`
  ${DISCOUNT_CODE_NODE_FRAGMENT}
  mutation DiscountCodeUpdate(
    $id: Int!
    $input: DiscountCodeUpdateInput!
    $eventsSkip: Int
    $eventsTake: Int
    $eventsFilters: EventFilterInput
    $eventsOrderBy: [EventOrderByInput!]
  ) {
    discountCodeUpdate(id: $id, input: $input) {
      ...DiscountCodeNode
    }
  }
`;

export const DISCOUNT_CODE_DELETE_MUTATION = gql`
  ${DISCOUNT_CODE_FRAGMENT}
  mutation DiscountCodeDelete($id: Int!) {
    discountCodeDelete(id: $id) {
      ...DiscountCode
    }
  }
`;
