import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "@ticketingplatform/ui/dist/setup/GlobalStyles";
import theme from "@ticketingplatform/ui/dist/theme";
import Typography from "@ticketingplatform/ui/dist/components/styles/Typography";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "setup/apollo";
import Router from "components/Router";
import VenuesProvider from "providers/VenuesProvider";
import IntlProvider from "providers/IntlProvider";

import { AppProps } from "./types";

const App: React.FC<AppProps> = ({ venues }) => (
  <ThemeProvider {...{ theme }}>
    <IntlProvider>
      <VenuesProvider value={venues}>
        <ApolloProvider client={apolloClient}>
          <Router />
          <GlobalStyles />
          <Typography />
        </ApolloProvider>
      </VenuesProvider>
    </IntlProvider>
  </ThemeProvider>
);

export default App;
