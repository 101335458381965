import colors from "./config/colors";
import { fonts, fontWeights, textStyles } from "./config/texts";

const theme = {
  fontSize: {
    xxl: "3.6rem",
    xl: "2.4rem",
    l: "1.8rem",
    m: "1.6rem",
    s: "1.4rem",
    xs: "1.2rem",
  },
  breakpoints: ["22.5rem", "48rem", "64rem", "90rem"],
  fonts,
  fontWeights,
  colors,
  textStyles,
  transition: "170ms ease-in-out",
  radii: {
    default: "0.375rem",
  },
  sizes: {
    inner: "80rem",
    defaultPadding: "1.25rem",
    summaryBarHeight: "3.375rem",
  },
};

export default theme;

export type Theme = typeof theme;
