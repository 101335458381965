import { gql } from "@apollo/client";

export const REFUND_FRAGMENT = gql`
  fragment Refund on Refund {
    id
    orderId
    amount
    reason
    stripeId
    stripeAccount
    method
    createdAt
    updatedAt
  }
`;
