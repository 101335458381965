import EventsSection from "@ticketingplatform/ui/dist/components/sections/EventsSection";
import { useIntl } from "react-intl";
import Header from "@ticketingplatform/ui/dist/components/organisms/Header";
import { useVenuesContext } from "providers/VenuesProvider";
import useVenuePicker from "hooks/useVenuePicker";
import useMonthPicker from "hooks/useMonthPicker";
import useNavItems from "hooks/useNavItems";

import { useEventList } from "./hooks";

const EventListPage: React.FC = () => {
  const intl = useIntl();
  const navItems = useNavItems();
  const venueIds = useVenuesContext();
  const monthPicker = useMonthPicker({ firstAsDefaultValue: true });
  const venuePicker = useVenuePicker({
    idIn: venueIds,
    firstAsDefaultValueIfSingleOption: true,
  });
  const { value: date } = monthPicker;
  const { value: venue } = venuePicker;
  const { items, loading, errorMessage } = useEventList(
    venue?.id,
    date?.month,
    date?.year
  );

  return (
    <>
      <Header items={navItems} />
      <EventsSection
        title={venue?.name}
        emptyMessage={intl.formatMessage({
          defaultMessage: "No events found. Try different filters.",
        })}
        venuePicker={
          venuePicker.options?.length === 1 ? undefined : venuePicker
        }
        {...{
          monthPicker,
          items,
          loading,
          errorMessage,
        }}
      />
    </>
  );
};

export default EventListPage;
