import { useMemo } from "react";
import { useOrderContext } from "providers/OrderProvider/hooks";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { ROUTE_PATHS } from "setup/routePaths";
import apolloErrorToSubmissionErrors from "utils/apolloErrorToSubmissionErrors";
import { useIntl } from "react-intl";
import { OrderStatus } from "@ticketingplatform/api/dist/__generated__/globalTypes";
import { Validators } from "@lemoncode/fonk";
import { matchField } from "@lemoncode/fonk-match-field-validator";
import phoneNumberValidator from "utils/phoneNumberValidator";
import { isTrue } from "@lemoncode/fonk-is-true-validator";
import {
  FinalFormValidation,
  createFinalFormValidation,
} from "@lemoncode/fonk-final-form";

import { UseBillingForm } from "./types";

export const useBillingFormValidation = (): FinalFormValidation => {
  const { formatMessage } = useIntl();

  return useMemo(
    () =>
      createFinalFormValidation({
        field: {
          firstName: [Validators.required],
          lastName: [Validators.required],
          email: [Validators.required, Validators.email],
          confirmEmail: [
            Validators.required,
            {
              validator: matchField,
              customArgs: { field: "email" },
            },
          ],
          phoneNumber: [Validators.required, phoneNumberValidator],
          notes: [],
          newsletterAgree: [],
          termsAgree: [
            {
              validator: isTrue,
              message: formatMessage({
                defaultMessage: "Please Accept Terms to Continue",
                description: "Terms Agree Validation Error Message",
              }),
            },
          ],
        },
      }),
    [formatMessage]
  );
};

export const useBillingForm: UseBillingForm = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { eventOccurrenceId } = useParams();
  const { order, setOrder, removeOrder } = useOrderContext();
  const { validateForm: validate } = useBillingFormValidation();

  return useMemo(
    () => ({
      submitLabel:
        order?.totalAmount === 0
          ? intl.formatMessage({ defaultMessage: "Confirm" })
          : intl.formatMessage({ defaultMessage: "Checkout" }),
      initialValues: {
        ...order,
        confirmEmail: order?.email,
      },
      validate,
      async onSubmit({
        firstName,
        lastName,
        email,
        phoneNumber,
        termsAgree,
        newsletterAgree,
        customerNotes,
      }) {
        if (!eventOccurrenceId) {
          return undefined;
        }

        try {
          const newOrder = await setOrder({
            firstName,
            lastName,
            email,
            phoneNumber,
            termsAgree,
            newsletterAgree,
            customerNotes,
            ...(order?.totalAmount === 0
              ? {
                  status: OrderStatus.CHECKOUT,
                }
              : {}),
          });

          if (newOrder?.status === OrderStatus.COMPLETED && newOrder.token) {
            removeOrder();

            navigate(
              generatePath(ROUTE_PATHS.EVENT_SUCCESS, {
                eventOccurrenceId,
                orderToken: newOrder.token,
              })
            );

            return undefined;
          }

          navigate(
            generatePath(ROUTE_PATHS.EVENT_CHECKOUT, {
              eventOccurrenceId,
            })
          );

          return undefined;
        } catch (error) {
          return apolloErrorToSubmissionErrors(error);
        }
      },
    }),
    [eventOccurrenceId, intl, navigate, order, removeOrder, setOrder, validate]
  );
};
