import { getEnvVar, getRequiredEnvVar } from "utils/getEnvVar";

const APPSYNC_URL = getRequiredEnvVar("REACT_APP_API_APPSYNC_URL");
const APPSYNC_KEY = getRequiredEnvVar("REACT_APP_API_APPSYNC_KEY");
const CURRENCY = getEnvVar("REACT_APP_CURRENCY") ?? "USD";

export default {
  APPSYNC_URL,
  APPSYNC_KEY,
  CURRENCY,
};
