import type { DebouncedFunc } from "lodash";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useRef } from "react";

const useDebounceFn = <TArgs, TReturn>(
  fn: (...args: TArgs[]) => TReturn,
  delay: number
) => {
  const debouncedRef = useRef<DebouncedFunc<(...args: TArgs[]) => TReturn>>();
  const fnRef = useRef(fn);

  useEffect(() => {
    fnRef.current = fn;
  }, [fn]);

  useEffect(() => {
    debouncedRef.current = debounce(
      (...args: TArgs[]): TReturn => fnRef.current(...args),
      delay
    );

    return () => {
      debouncedRef.current?.cancel();
    };
  }, [delay]);

  return useCallback((...args: TArgs[]) => debouncedRef.current?.(...args), []);
};

export default useDebounceFn;
