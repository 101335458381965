import { useEventOccurrenceQuery } from "@ticketingplatform/api/dist/event";
import { useParams, useSearchParams } from "react-router-dom";

import { UseEventOccurrence } from "./types";

const useEventOccurrence: UseEventOccurrence = () => {
  const { eventOccurrenceId } = useParams();
  const [searchParams] = useSearchParams();
  const { data, loading } = useEventOccurrenceQuery({
    variables: eventOccurrenceId
      ? {
          id: eventOccurrenceId,
          eventInclude: true,
          venueInclude: true,
          imageInclude: true,
          seatMapInclude: true,
          ticketPoolsInclude: true,
          ticketPoolsTake: -1,
          presalePasswordsInclude: true,
          presalePasswordsTake: -1,
          isPreview: searchParams.get("isPreview") === "true",
        }
      : undefined,
    skip: !eventOccurrenceId,
  });
  const { eventOccurrence } = data || {};

  return {
    eventOccurrence,
    loading,
  };
};

export default useEventOccurrence;
