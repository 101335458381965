import styled, { css } from "styled-components";
import media from "theme/config/media";
import { Text } from "components/styles//Typography/styles";

import { StyledEventsSectionHeroProps } from "./types";

export const EventsSection = styled.section`
  margin-bottom: 1.5rem;

  @media ${media.tablet} {
    margin: 3rem 0;
  }
`;

export const EventsSectionHeader = styled.div<StyledEventsSectionHeroProps>`
  position: relative;
  background-position: center;
  background-size: cover;
  min-height: ${({ $hasLogo }) => ($hasLogo ? "40rem" : "auto")};
  margin-left: -${({ theme }) => theme.sizes.defaultPadding};
  margin-right: -${({ theme }) => theme.sizes.defaultPadding};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2rem ${({ theme }) => theme.sizes.defaultPadding} 3.5rem;

  ${({ $heroBg }) =>
    $heroBg &&
    css`
      background-image: url(${$heroBg});

      &::before {
        content: "";
        position: absolute;
        inset: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(
          rgba(0, 0, 0, 0) 50%,
          rgb(0, 0, 0) 100%
        );
      }
    `}

  @media ${media.tablet} {
    background: none;
    margin: 0;
    padding: 0;
    min-height: auto;
    position: relative;
    color: ${({ theme }) => theme.colors.white};

    &::before {
      display: none;
    }
  }
`;

export const EventsSectionLogo = styled.div`
  position: relative;
  max-width: 30rem;
  margin: 0 auto 1rem;

  @media ${media.tablet} {
    display: none;
  }
`;

export const EventsSectionTitle = styled(Text)`
  display: none;

  @media ${media.tablet} {
    display: block;
    text-align: center;
  }

  @media ${media.desktop} {
    margin: 0 22rem;
  }
`;

export const EventsSectionFilters = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  z-index: 4;

  .select {
    min-width: 10rem;
  }

  @media ${media.tablet} {
    justify-content: flex-end;
    flex-direction: row;
  }

  @media ${media.desktop} {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
`;

export const EventsSectionMain = styled.div`
  margin-top: -1.5rem;

  @media ${media.tablet} {
    margin-top: 1.5rem;
  }
`;

export const EventsSectionLoading = styled.div`
  & > * {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media ${media.tablet} {
      flex-direction: row;
    }
  }
`;

export const EventsSectionEmpty = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  margin: 2.5rem 0.5rem;

  @media ${media.tablet} {
    margin: 1rem 22rem;
  }
`;

export const EventsSectionError = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  margin: 2.5rem 0.5rem;

  @media ${media.tablet} {
    margin: 1rem 22rem;
  }
`;
