import Text from "components/styles/Typography";

import FieldWrapper from "../FieldWrapper";

import * as Styled from "./styles";
import { CheckboxProps } from "./types";

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  label,
  error,
  variant = "primary",
  ...rest
}) => (
  <FieldWrapper
    {...{ error }}
    controller={
      <Styled.CheckboxWrapper {...{ variant }}>
        <Styled.CheckboxLabel htmlFor={id}>
          <Styled.CheckboxInput type="checkbox" {...{ id, variant, ...rest }} />
          <Styled.FakeCheckbox />
          <Text variant={variant === "secondary" ? "typo5" : "typo2"}>
            {label}
          </Text>
        </Styled.CheckboxLabel>
      </Styled.CheckboxWrapper>
    }
  />
);

export default Checkbox;
