import styled, { css } from "styled-components";
import { compose, layout, variant } from "styled-system";
import { Link } from "react-router-dom";

import { buttonSizes, buttonModes } from "./consts";
import { StyledButtonProps } from "./types";

const buttonCommonStyles = css<StyledButtonProps>`
  align-items: center;
  justify-content: center;
  border: 0;
  transition: ${({ theme }) => theme.transition};

  ${({ $fullW }) =>
    $fullW &&
    css`
      width: 100%;
    `}

  ${compose(
    layout,
    variant({
      prop: "$mode",
      variants: buttonModes,
    }),
    variant({
      prop: "$size",
      variants: buttonSizes,
    })
  )}

  &:disabled,
  &[disabled] {
    opacity: 0.6;
    pointer-events: none;
  }
`;

export const Button = styled.button<StyledButtonProps>`
  display: flex;
  ${buttonCommonStyles};
`;

export const ButtonLink = styled(Link)<StyledButtonProps>`
  display: inline-flex;
  text-decoration: none;
  ${buttonCommonStyles};
`;
