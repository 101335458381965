import {
  makeLazyQueryHook,
  makeMutationHook,
  makeQueryHook,
} from "utils/apolloUtils";

import { GetVenues, GetVenuesVariables } from "./__generated__/GetVenues";
import { GET_VENUE, GET_VENUES } from "./queries";
import { GetVenue, GetVenueVariables } from "./__generated__/GetVenue";
import { CREATE_VENUE, DELETE_VENUE, UPDATE_VENUE } from "./mutations";
import { CreateVenue, CreateVenueVariables } from "./__generated__/CreateVenue";
import { DeleteVenue, DeleteVenueVariables } from "./__generated__/DeleteVenue";
import { UpdateVenue, UpdateVenueVariables } from "./__generated__/UpdateVenue";

export const useVenueListQuery = makeQueryHook<GetVenues, GetVenuesVariables>(
  GET_VENUES
);

export const useVenueListLazyQuery = makeLazyQueryHook<
  GetVenues,
  GetVenuesVariables
>(GET_VENUES);

export const useVenueDetailsQuery = makeQueryHook<GetVenue, GetVenueVariables>(
  GET_VENUE
);

export const useVenueCreateMutation = makeMutationHook<
  CreateVenue,
  CreateVenueVariables
>(CREATE_VENUE);

export const useVenueUpdateMutation = makeMutationHook<
  UpdateVenue,
  UpdateVenueVariables
>(UPDATE_VENUE);

export const useVenueDeleteMutation = makeMutationHook<
  DeleteVenue,
  DeleteVenueVariables
>(DELETE_VENUE);
