import { makeApolloClient } from "@ticketingplatform/api/dist/config/apollo";
import { setContext } from "@apollo/client/link/context";

import environment from "./environment";

const authLink = setContext(async (operation, prevContext) => {
  const context = {
    ...operation.context,
    ...prevContext,
  };

  return {
    ...(context || {}),
    headers: {
      ...(context?.headers || {}),
      "x-api-key": environment.APPSYNC_KEY,
    },
  };
});

export const apolloClient = makeApolloClient([authLink]);
