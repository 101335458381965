import styled from "styled-components";

export const PresalePasswordInputComponent = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  gap: 12px;

  button {
    margin-top: 5px;
  }
`;

export const PresalePasswordInputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
`;
