import { gql } from "@apollo/client";
import { TICKET_POOL_FRAGMENT } from "ticketPool";
import { EVENT_FRAGMENT } from "event/fragments/event";
import { FILE_FRAGMENT } from "file/fragments";

import { TICKET_FRAGMENT } from "./ticket";

export const TICKET_WITH_RELATIONS_FRAGMENT = gql`
  ${TICKET_FRAGMENT}
  ${TICKET_POOL_FRAGMENT}
  ${EVENT_FRAGMENT}
  ${FILE_FRAGMENT}
  fragment TicketWithRelations on Ticket {
    ...Ticket
    ticketPool @include(if: $ticketPoolInclude) {
      ...TicketPool
      event @include(if: $eventInclude) {
        ...Event
      }
    }
    file @include(if: $fileInclude) {
      ...File
    }
    order @include(if: $orderInclude) {
      ...Order
    }
  }
`;
