import theme from "..";

const [smallPhone, phone, tablet, desktop] = theme.breakpoints;

const media = {
  smallPhone: `(min-width: ${smallPhone})`,
  phone: `(min-width: ${phone})`,
  tablet: `(min-width: ${tablet})`,
  desktop: `(min-width: ${desktop})`,
};

export default media;
