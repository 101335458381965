import { IntlConfig, IntlProvider as ReactIntlProvider } from "react-intl";
import React, { PropsWithChildren } from "react";
import en from "locale/compiled-locales/en.json";

export const defaultRichTextElements: IntlConfig["defaultRichTextElements"] = {
  p: (chunks) => <p>{chunks}</p>,
  br: () => <br />,
  strong: (chunks) => <strong>{chunks}</strong>,
  ul: (chunks) => <ul>{chunks}</ul>,
  li: (chunks) => <li>{chunks}</li>,
  a: (chunks) => <a>{chunks}</a>,
};

const messagesMap = {
  en,
} as const;

export type Locale = Uppercase<keyof typeof messagesMap>;

type LocalesMap = Record<Locale, keyof typeof messagesMap>;

export const locales = Object.keys(messagesMap).reduce<LocalesMap>(
  (result, locale) => ({
    ...result,
    [locale.toUpperCase()]: locale,
  }),
  {} as LocalesMap
);

const isCorrectLocale = (locale: string): locale is keyof typeof messagesMap =>
  !!Object.keys(messagesMap).includes(locale);

const getLocale = (): keyof typeof messagesMap => {
  const [locale] = navigator.language.split(/[-_]/);

  return isCorrectLocale(locale) ? locale : locales.EN;
};

const IntlProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const locale = getLocale();
  const messages = messagesMap[locale];

  return (
    <ReactIntlProvider
      {...{
        locale,
        messages,
        defaultRichTextElements,
      }}
      wrapRichTextChunksInFragment
    >
      {children}
    </ReactIntlProvider>
  );
};

export default IntlProvider;
