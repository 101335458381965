import { gql } from "@apollo/client";

import { ORGANIZATION_FRAGMENT } from "./fragments";

export const CREATE_ORGANIZATION = gql`
  ${ORGANIZATION_FRAGMENT}
  mutation CreateOrganization($input: OrganizationCreateInput!) {
    organizationCreate(input: $input) {
      ...Organization
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  ${ORGANIZATION_FRAGMENT}
  mutation UpdateOrganization($id: Int!, $input: OrganizationUpdateInput!) {
    organizationUpdate(id: $id, input: $input) {
      ...Organization
    }
  }
`;

export const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization($id: Int!) {
    organizationDelete(id: $id) {
      ...Organization
    }
  }
`;
