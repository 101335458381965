export const fonts = {
  openSans: '"Open Sans", "Trebuchet MS", sans-serif',
  poppins: '"Poppins", serif',
  barlow: '"Barlow", serif',
};

export const fontWeights = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
};

export const textStyles = {
  heading1: {
    fontSize: ["2rem", "", "2.125rem"],
    fontWeight: "bold",
  },
  heading2: {
    fontSize: "1.375rem",
    fontWeight: "bold",
    lineHeight: 1.3,
  },
  heading3: {
    fontSize: "1.125rem",
    fontWeight: "bold",
  },
  text: {
    fontSize: "1rem",
    fontWeight: "regular",
  },
  typo1: {
    fontSize: "1.25rem",
    fontWeight: "regular",
  },
  typo2: {
    fontSize: ["0.875rem", "", "1rem"],
    fontWeight: "bold",
  },
  typo3: {
    fontSize: "0.875rem",
    fontWeight: "semiBold",
  },
  typo4: {
    fontSize: "0.875rem",
    fontWeight: "regular",
  },
  typo5: {
    fontSize: "0.75rem",
    fontWeight: "regular",
  },
};
