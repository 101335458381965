export default {
  white: "#FFFFFF",
  black: "#000000",
  dodgerBlue: "#1D80FA",
  shark: "#363738",
  mineShaft: "#2C2C2C",
  cornflowerBlue: "#5469D4",
  blueDianne: "#1E5456",
  jungleGreen: "#269BA0",
  gray: "#909090",
  emperor: "#525252",
  tiara: "#C5D0D1",
  iron: "#D3D6DB",
  error: "#f56969",
  success: "#9AC838",
  darkGray: "#444",
  deepSeaDive: "#336363",
  lighthouse: "#F4F4F4",
  basketball: "#F16630",
  orochimaru: "#d9d9d9",
  distantWindChime: "#ebeff2",
};
