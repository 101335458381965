import styled from "styled-components";
import { Text } from "components/styles/Typography/styles";

export const FieldWrapperLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FieldWrapperError = styled(Text)`
  color: ${({ theme }) => theme.colors.error};
  text-align: left;
  margin-top: 0.25rem;
  display: block;
  overflow-wrap: break-word;
`;
