import Text from "components/styles/Typography";

import { InfoBarProps } from "./types";
import * as Styled from "./styles";

const InfoBar: React.FC<InfoBarProps> = ({ label, $isFixed = false }) => (
  <Styled.InfoBar {...{ $isFixed }}>
    <Text variant="typo3" as="div">
      {label}
    </Text>
  </Styled.InfoBar>
);

export default InfoBar;
