import { Text } from "components/styles/Typography/styles";
import styled from "styled-components";

export const CheckoutForm = styled.form``;

export const CheckoutTicketsTypes = styled.div`
  margin-bottom: 1rem;
`;

export const CheckoutTicketsTypesItem = styled(Text)`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  width: 100%;

  &:not(las-child) {
    margin-bottom: 0.25rem;
  }
`;

export const CheckoutTicketsDetailsText = styled(Text)`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  width: 100%;
  margin-bottom: 0.25rem;
`;
