import { gql } from "@apollo/client";

export const SEAT_MAP_FRAGMENT = gql`
  fragment SeatMap on SeatMap {
    id
    name
    venueId
    imageId
  }
`;
