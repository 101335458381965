import { FormattedMessage } from "react-intl";
import InfoBar from "@ticketingplatform/ui/dist/components/organisms/InfoBar";
import Button from "@ticketingplatform/ui/dist/components/atoms/Button";
import useCountdown from "hooks/useCountdown";

import { SessionInfoBarProps } from "./types";
import * as Styled from "./styles";

const SessionInfoBar: React.FC<SessionInfoBarProps> = ({
  date,
  interval,
  onFinish,
  onRestartSession,
  ...rest
}) => {
  const [value] = useCountdown({ date, interval, onFinish });

  return (
    <InfoBar
      {...rest}
      label={
        value === 0 ? (
          <Styled.SessionExpiredWrapper>
            <FormattedMessage defaultMessage="Your session has expired" />
            <Button $mode="secondary" $size="big" onClick={onRestartSession}>
              <FormattedMessage defaultMessage="Start new Session" />
            </Button>
          </Styled.SessionExpiredWrapper>
        ) : (
          <FormattedMessage
            defaultMessage="{value, date, ::mm:ss} Remaining"
            values={{ value }}
          />
        )
      }
    />
  );
};

export default SessionInfoBar;
