import { gql } from "@apollo/client";

export const TICKET_FRAGMENT = gql`
  fragment Ticket on Ticket {
    id
    orderId
    date
    ticketPoolId
    name
    price
    discountAmount
    platformFeeSubtotalAmount
    platformFeeAmount
    paymentFeeAmount
    venueFeeSubtotalAmount
    venueFeeAmount
    totalAmount
    expireAt
    ticketNumber
    status
    fileId
    createdAt
    updatedAt
  }
`;
