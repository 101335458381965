import Header from "@ticketingplatform/ui/dist/components/organisms/Header";
import useNavItems from "hooks/useNavItems";
import CartBillingSection from "@ticketingplatform/ui/dist/components/sections/Checkout/CartBillingSection";
import { FormattedMessage, useIntl } from "react-intl";
import SessionInfoBar from "components/SessionInfoBar";
import useEventHeading from "hooks/useEventHeading";
import useSessionInfoBar from "hooks/useSessionInfoBar";
import useSummaryBar from "hooks/useSummaryBar";
import { formatTermsLink } from "utils/intl";

import { useBillingForm } from "./hooks";
import * as Styled from "./styles";

const CartBillingPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const navItems = useNavItems();
  const eventHeading = useEventHeading();
  const billingForm = useBillingForm();
  const summaryBar = useSummaryBar();
  const sessionInfoBar = useSessionInfoBar();

  return (
    <>
      <Header href="/" items={navItems} withCloseIcon closeHref="/" />
      <CartBillingSection
        firstNamePlaceholderLabel={formatMessage({
          defaultMessage: "First Name",
          description: "First name placeholder label",
        })}
        lastNamePlaceholderLabel={formatMessage({
          defaultMessage: "Last Name",
          description: "Last name placeholder label",
        })}
        emailPlaceholderLabel={formatMessage({
          defaultMessage: "Email",
          description: "Email placeholder label",
        })}
        confirmEmailPlaceholderLabel={formatMessage({
          defaultMessage: "Confirm email",
          description: "Confirm email placeholder label",
        })}
        phoneNumberPlaceholderLabel={formatMessage({
          defaultMessage: "Phone Number",
          description: "Phone number placeholder label",
        })}
        notesPlaceholderLabel={formatMessage({
          defaultMessage: "Special Requests",
          description: "Notes placeholder label",
        })}
        newsletterCheckboxLabel={formatMessage({
          defaultMessage: "Sign up to Newsletter",
          description: "Newsletter checkbox label",
        })}
        termsCheckboxLabel={
          <Styled.FieldLabel>
            <FormattedMessage
              defaultMessage="I agree to <a>Terms</a>"
              description="Terms checkbox label"
              values={{ a: formatTermsLink }}
            />
          </Styled.FieldLabel>
        }
        {...billingForm}
        {...{ eventHeading, summaryBar }}
      />
      {sessionInfoBar && <SessionInfoBar {...sessionInfoBar} />}
    </>
  );
};

export default CartBillingPage;
