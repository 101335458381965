import React, { useState } from "react";
import { AspectRatio } from "react-aspect-ratio";

import * as Styled from "./styles";
import { VideoProps } from "./types";
import VideoPlaceholder from "./partials/VideoPlaceholder";

const Video: React.FC<VideoProps> = ({
  src,
  alt,
  ratio = 700 / 400,
  placeholderSrc,
}) => {
  const [isVideoVisible, setVisible] = useState(false);

  return !isVideoVisible && placeholderSrc ? (
    <VideoPlaceholder
      src={placeholderSrc}
      onClick={() => setVisible(!isVideoVisible)}
      {...{ alt, ratio }}
    />
  ) : (
    <Styled.VideoWrapper>
      <AspectRatio {...{ ratio }}>
        <Styled.VideoIframe
          {...{ src }}
          frameBorder="0"
          allowFullScreen
          title="Video"
        />
      </AspectRatio>
    </Styled.VideoWrapper>
  );
};

export default Video;
