import { gql } from "@apollo/client";

export const VENUE_FRAGMENT = gql`
  fragment Venue on Venue {
    id
    name
    platformFee
    addressId
    stripeAccountId
    organizationId
    timezone
    createdAt
    updatedAt
    priceDisplay
  }
`;
