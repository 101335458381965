import styled from "styled-components";
import { Text } from "components/styles//Typography/styles";

export const ErrorMessage = styled(Text)`
  text-align: center;
  margin-top: 3rem;
`;

export const ErrorMessageButton = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;
