import { ApolloError } from "@apollo/client";
import { FORM_ERROR } from "final-form";

const apolloErrorToSubmissionErrors = (
  error: unknown
): Record<string, string> | undefined => {
  if (!error || typeof error !== "object" || !("graphQLErrors" in error)) {
    return undefined;
  }

  const { graphQLErrors } = error as ApolloError;

  return graphQLErrors.reduce(
    (reduced, { errorInfo, message }) => ({
      ...reduced,
      [errorInfo?.path?.split(".").slice(-1).pop() || FORM_ERROR]: message,
    }),
    {}
  );
};

export default apolloErrorToSubmissionErrors;
