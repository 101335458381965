import { useMemo } from "react";

import { UseFakeForm } from "./types";

export const useFakeForm: UseFakeForm = ({ onSubmit }) =>
  useMemo(
    () => ({
      onClick(event) {
        if ("type" in event.target && event.target.type === "submit") {
          event.preventDefault();
          onSubmit?.();
        }
      },
      onKeyDown(event) {
        if (event.key === "Enter") {
          event.preventDefault();
          onSubmit?.();
        }
      },
    }),
    [onSubmit]
  );
