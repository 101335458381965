import Inner from "components/styles/Inner";
import EventDetails from "components/organisms/EventDetails";
import Image from "components/atoms/Image";
import Wysiwyg from "components/atoms/Wysiwyg";
import Video from "components/atoms/Video";
import Skeleton from "components/atoms/Skeleton";
import SummaryBar from "components/organisms/SummaryBar";

import * as Styled from "./styles";
import { EventDetailsSectionProps } from "./types";

const EventDetailsSection: React.FC<EventDetailsSectionProps> = ({
  image,
  video,
  seatMap,
  description,
  bootleServiceCheckbox,
  eventHeading,
  loading,
  summaryBar,
  submitLabel,
  isEventInThePast,
  onSubmit,
  ...rest
}) => (
  <Styled.EventDetailsSection>
    <Inner>
      <Styled.EventDetailsSectionBody>
        {image || loading ? (
          <Styled.EventDetailsSectionMedia>
            {loading ? <Skeleton height={400} /> : <Image {...image} />}
          </Styled.EventDetailsSectionMedia>
        ) : (
          <div style={{ gridRow: "1", gridColumn: "1" }} />
        )}
        {description || loading ? (
          <Styled.EventDetailsSectionWysiwyg>
            {loading ? (
              <Skeleton count={5} />
            ) : (
              <Wysiwyg>{description}</Wysiwyg>
            )}
          </Styled.EventDetailsSectionWysiwyg>
        ) : (
          <div style={{ gridRow: "4", gridColumn: "1" }} />
        )}
        {video ? (
          <Styled.EventDetailsSectionVideo>
            <Video {...video} />
          </Styled.EventDetailsSectionVideo>
        ) : (
          <div style={{ gridRow: "5", gridColumn: "1" }} />
        )}
        {loading || eventHeading || bootleServiceCheckbox ? (
          <Styled.EventDetailsSectionTickets>
            <EventDetails
              {...{
                loading,
                eventHeading,
                bootleServiceCheckbox,
                isEventInThePast,
                ...rest,
              }}
            />
          </Styled.EventDetailsSectionTickets>
        ) : (
          <div style={{ gridRow: "2", gridColumn: "1" }} />
        )}
        {seatMap ? (
          <Styled.EventDetailsSectionSeatMap>
            <Image {...seatMap} />
          </Styled.EventDetailsSectionSeatMap>
        ) : (
          <div style={{ gridRow: "3", gridColumn: "1" }} />
        )}
      </Styled.EventDetailsSectionBody>
    </Inner>
    {!isEventInThePast && (
      <SummaryBar
        {...summaryBar}
        onClick={onSubmit}
        buttonLabel={submitLabel || summaryBar.buttonLabel}
      />
    )}
  </Styled.EventDetailsSection>
);

export default EventDetailsSection;
