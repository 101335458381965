import styled from "styled-components";

export const CartBillingForm = styled.form`
  display: grid;
  gap: 1rem;
`;

export const CartBillingFormAgreements = styled.div`
  display: grid;
  gap: 0.375rem;
`;
