import styled from "styled-components";
import { variant } from "styled-system";

import { StyledQuantityPickerProps } from "./types";

export const QuantityPicker = styled.div`
  display: flex;
  height: 2.5rem;
  color: ${({ theme }) => theme.colors.white};
`;

export const QuantityPickerButton = styled.button<StyledQuantityPickerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  transition: ${({ theme }) => theme.transition};
  padding: 0 1rem;
  color: currentColor;
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: 1;

  &:disabled,
  &[disabled] {
    opacity: 0.6;
    pointer-events: none;
  }

  ${variant({
    variants: {
      primary: {
        backgroundColor: "dodgerBlue",
        "&:hover": {
          backgroundColor: ["", "", "cornflowerBlue"],
        },
      },
      secondary: {
        backgroundColor: "blueDianne",
        "&:hover": {
          backgroundColor: ["", "", "deepSeaDive"],
        },
      },
    },
  })}
`;

export const QuantityPickerValue = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  width: 3rem;
  border: 0;
  border-radius: 0;
  text-align: center;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const QuantityPickerButtonLeft = styled(QuantityPickerButton)`
  border-radius: 0.25rem 0 0 0.25rem;
`;

export const QuantityPickerButtonRight = styled(QuantityPickerButton)`
  border-radius: 0 0.25rem 0.25rem 0;
`;
