import { Form } from "react-final-form";
import Button from "components/atoms/Button";
import InputField from "components/atoms/Fields/Input/InputField";

import { DiscountCodeFormProps } from "./types";
import * as Styled from "./styles";

const DiscountCodeForm: React.FC<DiscountCodeFormProps> = ({
  discountCodePlaceholderLabel,
  submitButtonLabel,
  ...formProps
}) => (
  <Form
    {...formProps}
    render={({ handleSubmit, submitting, values }) => (
      <Styled.DiscountCodeForm onSubmit={handleSubmit}>
        <Styled.DiscountCodeInput>
          <InputField
            name="discountCode"
            placeholder={discountCodePlaceholderLabel}
          />
        </Styled.DiscountCodeInput>
        <Styled.DiscountCodeButton>
          <Button type="submit" disabled={submitting || !values.discountCode}>
            {submitButtonLabel}
          </Button>
        </Styled.DiscountCodeButton>
      </Styled.DiscountCodeForm>
    )}
  />
);

export default DiscountCodeForm;
