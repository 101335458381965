import { useEffect, useState } from "react";

import { UseTicketsListValue } from "./types";

export const useTicketsListValue: UseTicketsListValue = ({
  items: itemsProp,
  onItemsChange,
}) => {
  const [items, setItems] = useState(itemsProp || []);

  useEffect(() => {
    setItems(itemsProp || []);
  }, [itemsProp]);

  return {
    items,
    handleItemChange(itemIndex) {
      return (newValue: number) => {
        const newItems = items.map((item, index) =>
          index === itemIndex ? { ...item, value: newValue } : item
        );

        setItems(newItems);
        onItemsChange?.(newItems);
      };
    },
  };
};
