import styled from "styled-components";
import { color, compose, space, typography, variant } from "styled-system";
import { textStyles } from "theme/config/texts";

import { TextProps } from "./types";

export const Text = styled.p<TextProps>`
  ${compose(
    typography,
    space,
    color,
    variant({
      variants: {
        ...textStyles,
      },
    })
  )};
`;
