import { gql } from "@apollo/client";

export const EVENT_FRAGMENT = gql`
  fragment Event on Event {
    id
    eventTemplateId
    ageLimit
    date
    doorTime
    name
    publishAt
    sendTicketsAt
    status
    type
    venueId
    youtubeUrl
    description
    timezone
  }
`;
