import { CSSProperties, useMemo } from "react";
import { GroupBase, StylesConfig } from "react-select";
import { useTheme } from "styled-components";

import { SelectMode } from "./types";

const useCustomStyles = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  $mode: SelectMode
): StylesConfig<Option, IsMulti, Group> => {
  const { transition, fontWeights, colors } = useTheme();
  const modes = useMemo(
    () => ({
      control: {
        light: {
          backgroundColor: colors.iron,
          color: colors.black,
        },
        colored: {
          backgroundColor: colors.dodgerBlue,
          color: colors.white,
        },
        white: {
          backgroundColor: colors.white,
          color: colors.black,
          border: `1px solid ${colors.iron}`,

          "&:hover": {
            border: `1px solid ${colors.iron}`,
          },
        },
      },
      option: {
        light: {
          color: colors.black,
        },
        colored: {
          color: colors.white,
        },
        white: {
          color: colors.black,
          backgroundColor: colors.white,
          justifyContent: "left" as CSSProperties["justifyContent"],

          "&:hover": {
            backgroundColor: colors.lighthouse,
          },
        },
      },
      singleValue: {
        light: {
          color: colors.black,
        },
        colored: {
          color: colors.white,
        },
        white: {
          color: colors.black,
        },
      },
      menu: {
        light: {
          backgroundColor: colors.iron,
        },
        colored: {
          backgroundColor: colors.dodgerBlue,
        },
        white: {
          backgroundColor: colors.white,
        },
      },
      placeholder: {
        light: {
          color: colors.black,
        },
        colored: {
          color: colors.white,
        },
        white: {
          color: colors.gray,
          position: "absolute" as CSSProperties["position"],
          left: ".125rem",
        },
      },
      indicatorsContainer: {
        light: {
          color: colors.black,
        },
        colored: {
          color: colors.white,
        },
        white: {
          color: colors.black,
        },
      },
      menuList: {
        light: {},
        colored: {},
        white: {
          border: `1px solid ${colors.iron}`,
        },
      },
    }),
    [colors]
  );

  return useMemo(
    () => ({
      control: (styles, { menuIsOpen }) => ({
        ...styles,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "0.875rem",
        fontWeight: fontWeights.semiBold,
        height: "2.5rem",
        padding: "0.125rem 0.75rem",
        border: 0,
        borderRadius: menuIsOpen ? "0.125rem 0.125rem 0 0" : "0.125rem",
        minHeight: "auto",
        boxShadow: "none",
        cursor: "pointer",
        transition: "none",
        ...modes.control[$mode],
      }),
      valueContainer: (styles) => ({
        ...styles,
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }),
      option: (styles, { isSelected }) => ({
        ...styles,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        height: "2.5rem",
        fontSize: "0.875rem",
        padding: "0.125rem 0.75rem",
        backgroundColor: isSelected ? colors.gray : "transparent",
        transition,
        textAlign: "center",

        "&:hover": {
          backgroundColor: colors.gray,
        },

        ...modes.option[$mode],
      }),
      singleValue: (styles) => ({
        ...styles,
        ...modes.singleValue[$mode],
      }),
      menu: (styles) => ({
        ...styles,
        margin: 0,
        boxShadow: "none",
        overflow: "hidden",
        fontWeight: fontWeights.semiBold,
        borderRadius: "0 0 0.125rem 0.125rem",
        ...modes.menu[$mode],
      }),
      menuList: (styles) => ({
        ...styles,
        ...modes.menuList[$mode],
        padding: 0,
      }),
      input: (styles) => ({
        ...styles,
      }),
      placeholder: (styles) => ({
        ...styles,
        margin: 0,
        font: "inherit",
        ...modes.placeholder[$mode],
      }),
      indicatorSeparator: () => ({
        display: "none",
      }),
      indicatorsContainer: (styles) => ({
        ...styles,
        marginLeft: "0.5rem",
        "> div": {
          padding: 0,
          color: modes.indicatorsContainer[$mode].color,
          "&:hover": {
            color: modes.indicatorsContainer[$mode].color,
          },
          "> svg": {
            height: "1em",
            width: "1em",
          },
        },
      }),
    }),
    [modes, $mode, fontWeights, transition, colors]
  );
};

export default useCustomStyles;
