import Nav from "components/organisms/Header/partials/Nav";

import * as Styled from "./styles";
import { HeaderProps } from "./types";

const Header: React.FC<HeaderProps> = ({
  $shouldHideOnMobile = false,
  items,
  ...rest
}) => (
  <Styled.Header {...{ $shouldHideOnMobile }}>
    {items && <Nav {...{ items, ...rest }} />}
  </Styled.Header>
);

export default Header;
