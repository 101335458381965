import { useIntl } from "react-intl";
import { useEffect, useMemo, useState } from "react";
import {
  formatDateTime,
  getSaleDate,
  parseDate,
  zonedTimeToLocal,
} from "utils/date";
import { useOrderContext } from "providers/OrderProvider/hooks";
import isPast from "date-fns/isPast";
import isFuture from "date-fns/isFuture";
import { TicketsListItemProps } from "@ticketingplatform/ui/dist/components/molecules/TicketsList/types";
import useDebounceFn from "hooks/useDebounceFn";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "setup/routePaths";
import useEventOccurrence from "hooks/useEventOccurrence";
import { EventDetailsSectionProps } from "@ticketingplatform/ui/dist/components/sections/EventDetailsSection/types";
import environment from "setup/environment";
import getYoutubeEmbedUrl from "utils/getYoutubeEmbedUrl";
import getYoutubeVideoId from "utils/getYoutubeVideoId";
import { areFeesIncluded, getPriceToDisplay } from "utils/priceDisplay";

import { checkAvailableNow, getPresalesPasswords } from "./utils";

export const useEventDetails = (): Pick<
  EventDetailsSectionProps,
  | "image"
  | "description"
  | "video"
  | "seatMap"
  | "onSubmit"
  | "loading"
  | "presale"
  | "isEventInThePast"
> => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { eventOccurrence, loading } = useEventOccurrence();
  const { order } = useOrderContext();

  return useMemo(() => {
    const eventDate = parseDate(eventOccurrence?.date);

    const currentDate = new Date();

    if (loading || !eventOccurrence || !eventDate) {
      return { loading };
    }

    const formattedEventDate = zonedTimeToLocal(
      eventDate,
      eventOccurrence.event.timezone
    );

    const {
      event: {
        name,
        image,
        description,
        youtubeUrl,
        seatMap,
        presalePasswords,
        timezone,
      },
    } = eventOccurrence;
    const youtubeEmbedUrl = getYoutubeEmbedUrl(getYoutubeVideoId(youtubeUrl));

    return {
      isEventInThePast: currentDate > formattedEventDate,
      image: image?.url
        ? {
            src: image.url,
            alt: intl.formatMessage(
              { defaultMessage: "{name} poster" },
              { name }
            ),
          }
        : undefined,
      description: description && (
        <div dangerouslySetInnerHTML={{ __html: description }} />
      ),
      video: youtubeEmbedUrl
        ? {
            src: youtubeEmbedUrl,
          }
        : undefined,
      seatMap: seatMap?.image?.url
        ? {
            src: seatMap?.image?.url,
            alt: seatMap.name,
          }
        : undefined,
      presale:
        getPresalesPasswords(presalePasswords?.nodes, timezone || undefined) ||
        [],
      onSubmit() {
        const expireAt = parseDate(order?.expireAt);

        if (
          !eventOccurrence ||
          !expireAt ||
          isPast(expireAt) ||
          !order?.tickets?.nodes.length
        ) {
          return;
        }

        navigate(
          generatePath(ROUTE_PATHS.EVENT_BILLING, {
            eventOccurrenceId: eventOccurrence.id,
          })
        );
      },
    };
  }, [
    eventOccurrence,
    intl,
    loading,
    navigate,
    order?.expireAt,
    order?.tickets?.nodes.length,
  ]);
};

export const useEventTickets = (
  presalePassword?: string
): Pick<
  EventDetailsSectionProps,
  | "items"
  | "onItemsChange"
  | "onSubmit"
  | "loading"
  | "disabled"
  | "ticketsAvailableNow"
> & { includeAllFees: boolean } => {
  const intl = useIntl();
  const { eventOccurrence, loading } = useEventOccurrence();
  const { loading: disabled, order, error, setOrder } = useOrderContext();

  const [localQuantities, setLocalQuantities] = useState<
    Record<number, number>
  >({});

  useEffect(() => {
    const initialQuantities = (order?.tickets?.nodes || []).reduce<
      Record<number, number>
    >(
      (prev, { ticketPoolId }) => ({
        ...prev,
        [ticketPoolId]: (prev[ticketPoolId] ?? 0) + 1,
      }),
      {}
    );

    setLocalQuantities(initialQuantities);
  }, [order?.tickets?.nodes]);

  const onItemsChange = useDebounceFn((items: TicketsListItemProps[]) => {
    const newQuantities = items.reduce<Record<number, number>>(
      (prev, { ticketPoolId, value }) =>
        ticketPoolId
          ? {
              ...prev,
              [ticketPoolId]: value,
            }
          : prev,
      {}
    );

    setLocalQuantities(newQuantities);
    setOrder({
      tickets: items.map(({ ticketPoolId, value }) => ({
        ticketPoolId,
        eventOccurrenceId: eventOccurrence?.id,
        quantity: value,
      })),
      presalePassword,
    });
  }, 1000);

  const ticketsAvailableNow = checkAvailableNow(
    eventOccurrence?.event?.ticketPools?.nodes,
    eventOccurrence?.event?.date
  );

  const items = useMemo(() => {
    const eventDate = parseDate(eventOccurrence?.date);

    if (!eventDate) {
      return [];
    }

    const { ticketPools, timezone, venue } = eventOccurrence?.event || {};
    const includeAllFees = areFeesIncluded(venue?.priceDisplay);

    return ticketPools?.nodes
      .map(
        (
          {
            id,
            name,
            description,
            price,
            priceWithFees,
            availableBeforeDays,
            availableBeforeTime,
            expireBeforeDays,
            expireBeforeTime,
            quantity: maxValue,
            isScannerOnly,
          },
          index
        ) => {
          const saleAvailableAt =
            availableBeforeDays !== null &&
            availableBeforeTime !== null &&
            getSaleDate(eventDate, availableBeforeDays, availableBeforeTime);

          const saleExpireAt =
            expireBeforeDays !== null &&
            expireBeforeTime &&
            getSaleDate(eventDate, expireBeforeDays, expireBeforeTime);

          const localSaleAvailableAt =
            saleAvailableAt && zonedTimeToLocal(saleAvailableAt, timezone);

          const localSaleExpireAt =
            saleExpireAt && zonedTimeToLocal(saleExpireAt, timezone);

          const isSaleAvailable =
            (!localSaleAvailableAt || isPast(localSaleAvailableAt)) &&
            (!localSaleExpireAt || isFuture(localSaleExpireAt));

          const displayPrice = getPriceToDisplay(
            { price, priceWithFees },
            venue?.priceDisplay
          );

          const feeLabel = includeAllFees
            ? intl.formatMessage({
                defaultMessage: "(includes fees)",
                description: "includes Fees label",
              })
            : intl.formatMessage({
                defaultMessage: " + Fees",
                description: "Fees label",
              });

          return {
            name,
            ticketPoolId: id,
            key: id,
            error: error?.graphQLErrors.find((graphQLError) =>
              graphQLError.errorInfo?.path?.includes(`input.tickets.[${index}]`)
            )?.message,
            price: displayPrice
              ? intl.formatNumber(displayPrice, {
                  style: "currency",
                  currency: environment.CURRENCY,
                })
              : intl.formatMessage({
                  defaultMessage: "Free",
                }),
            feeLabel: price > 0 ? feeLabel : undefined,
            dateTime: saleAvailableAt
              ? formatDateTime(saleAvailableAt, timezone)
              : undefined,
            tooltipLabel: description && (
              <div dangerouslySetInnerHTML={{ __html: description }} />
            ),
            disabled: !isSaleAvailable,
            hidden: isScannerOnly,
            value: localQuantities[id] ?? 0,
            maxValue,
          };
        }
      )
      .filter((i) => !i.hidden);
  }, [
    error?.graphQLErrors,
    eventOccurrence?.date,
    eventOccurrence?.event,
    intl,
    localQuantities,
  ]);

  return {
    items,
    onItemsChange,
    ticketsAvailableNow,
    loading,
    disabled,
    includeAllFees: areFeesIncluded(eventOccurrence?.event.venue?.priceDisplay),
  };
};
