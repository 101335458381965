import Skeleton from "components/atoms/Skeleton";
import Text from "components/styles/Typography";

import * as Styled from "./styles";
import { EventHeadingProps } from "./types";

const EventHeading: React.FC<EventHeadingProps> = ({
  dateTime,
  name,
  venue,
  loading,
  $isLeftOnDesktop,
}) => (
  <Styled.EventHeading {...{ $isLeftOnDesktop }}>
    <Text variant="typo2" color="dodgerBlue" mb="0.25rem">
      {loading ? <Skeleton inline /> : dateTime}
    </Text>
    <Text variant="heading2" mb="0.125rem">
      {loading ? <Skeleton inline /> : name}
    </Text>
    <Text variant="typo2">{loading ? <Skeleton inline /> : venue}</Text>
  </Styled.EventHeading>
);

export default EventHeading;
