import { useCallback, useEffect, useRef, useState } from "react";

import { getCountdownValue } from "./utils";
import { UseCountdown } from "./types";

const useCountdown: UseCountdown = ({ date, interval = 1000, onFinish }) => {
  const [value, setValue] = useState(getCountdownValue(date));
  const timerRef = useRef<number>();
  const onFinishRef = useRef(onFinish);

  const stopCountdown = useCallback(() => {
    window.clearInterval(timerRef.current);
  }, []);

  useEffect(() => {
    setValue(getCountdownValue(date));
  }, [date]);

  useEffect(() => {
    onFinishRef.current = onFinish;
  }, [onFinish]);

  useEffect(() => {
    timerRef.current = window.setInterval(() => {
      setValue((prevValue) => {
        if (prevValue > interval) {
          return prevValue - interval;
        }

        stopCountdown();
        onFinishRef.current?.();

        return 0;
      });
    }, interval);

    return () => {
      stopCountdown();
    };
  }, [interval, date, stopCountdown]);

  return [value, stopCountdown];
};

export default useCountdown;
