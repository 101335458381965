import styled, { css } from "styled-components";
import media from "theme/config/media";

import { StyledHeaderProps } from "./types";

export const Header = styled.header<StyledHeaderProps>`
  padding: 1.25rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkGray};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.5rem;

  ${({ $shouldHideOnMobile }) =>
    $shouldHideOnMobile &&
    css`
      display: none;
    `}

  @media ${media.tablet} {
    padding: 2.5rem 1.25rem;
    background-color: ${({ theme }) => theme.colors.black};
    border-bottom: none;
    height: 7rem;

    ${({ $shouldHideOnMobile }) =>
      $shouldHideOnMobile &&
      css`
        display: block;
      `}
  }
`;
