import CartBillingForm from "components/organisms/CartBillingForm";

import Checkout from "..";

import { CartBillingSectionProps } from "./types";

const CartBillingSection: React.FC<CartBillingSectionProps> = ({
  eventHeading,
  ...rest
}) => (
  <Checkout {...{ eventHeading }}>
    <CartBillingForm {...rest} />
  </Checkout>
);

export default CartBillingSection;
