import { gql } from "@apollo/client";
import { FILE_FRAGMENT } from "file/fragments";

import { ORDER_WITH_RELATIONS_FRAGMENT } from "./fragments/orderWithRelations";

export const ORDER_CREATE_MUTATION = gql`
  ${ORDER_WITH_RELATIONS_FRAGMENT}
  mutation OrderCreate(
    $input: OrderCreateInput!
    $ticketsInclude: Boolean = false
    $ticketsSkip: Int
    $ticketsTake: Int
    $ticketsFilters: TicketFilterInput
    $ticketsOrderBy: [TicketOrderByInput!]
    $discountCodeInclude: Boolean = false
    $ticketPoolInclude: Boolean = false
    $eventInclude: Boolean = false
    $fileInclude: Boolean = false
    $refundsInclude: Boolean = false
    $refundsSkip: Int
    $refundsTake: Int
    $refundsFilters: RefundFilterInput
    $refundsOrderBy: [RefundOrderByInput!]
    $orderInclude: Boolean = false
  ) {
    orderCreate(input: $input) {
      ...OrderWithRelations
    }
  }
`;

export const ORDER_UPDATE_MUTATION = gql`
  ${ORDER_WITH_RELATIONS_FRAGMENT}
  mutation OrderUpdate(
    $token: String!
    $input: OrderCreateInput!
    $ticketsInclude: Boolean = false
    $ticketsSkip: Int
    $ticketsTake: Int
    $ticketsFilters: TicketFilterInput
    $ticketsOrderBy: [TicketOrderByInput!]
    $discountCodeInclude: Boolean = false
    $ticketPoolInclude: Boolean = false
    $eventInclude: Boolean = false
    $refundsInclude: Boolean = false
    $refundsSkip: Int
    $refundsTake: Int
    $fileInclude: Boolean = false
    $refundsFilters: RefundFilterInput
    $refundsOrderBy: [RefundOrderByInput!]
    $orderInclude: Boolean = false
  ) {
    orderUpdate(token: $token, input: $input) {
      ...OrderWithRelations
    }
  }
`;

export const ORDERS_EXPORT_MUTATION = gql`
  ${FILE_FRAGMENT}
  mutation OrdersExport(
    $filters: OrderFilterInput
    $orderBy: [OrderOrderByInput!]
  ) {
    ordersExport(filters: $filters, orderBy: $orderBy) {
      ...File
    }
  }
`;
