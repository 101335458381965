import styled from "styled-components";
import media from "theme/config/media";

import CarrotRight from "../../../../../assets/svg/carot-right.svg";

export const VideoPlaceholder = styled.div`
  position: relative;
`;

export const VideoPlaceholderButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.dodgerBlue};
  transition: ${({ theme }) => theme.transition};
  border: 0;
  padding: 0;

  @media ${media.tablet} {
    &:hover {
      color: ${({ theme }) => theme.colors.cornflowerBlue};
    }
  }
`;

export const VideoPlaceholderIcon = styled(CarrotRight)`
  font-size: 3.5em;
  fill: currentColor;

  @media ${media.phone} {
    font-size: 8rem;
  }
`;
