import Inner from "components/styles/Inner";
import EventHeading from "components/molecules/EventHeading";
import { PropsWithChildren } from "react";

import * as Styled from "./styles";
import { CheckoutProps } from "./types";

const Checkout: React.FC<PropsWithChildren<CheckoutProps>> = ({
  children,
  eventHeading,
}) => (
  <Styled.CheckoutSection>
    <Inner>
      <EventHeading {...eventHeading} />
      <Styled.CheckoutWrapper>{children}</Styled.CheckoutWrapper>
    </Inner>
  </Styled.CheckoutSection>
);

export default Checkout;
