import { FORM_ERROR } from "final-form";
import { groupBy } from "lodash";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { OrderStatus } from "@ticketingplatform/api/dist/__generated__/globalTypes";
import {
  CheckoutFormProps,
  CheckoutTicketsItemProps,
} from "@ticketingplatform/ui/dist/components/organisms/CheckoutForm/types";
import { DiscountCodeFormProps } from "@ticketingplatform/ui/dist/components/molecules/DiscountCodeForm/types";
import { useOrderContext } from "providers/OrderProvider/hooks";
import apolloErrorToSubmissionErrors from "utils/apolloErrorToSubmissionErrors";
import environment from "setup/environment";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { ROUTE_PATHS } from "setup/routePaths";
import useEventOccurrence from "hooks/useEventOccurrence";
import { areFeesIncluded } from "utils/priceDisplay";

const createFallbackLink = (checkoutUrl: string) => {
  alert("Popup blocked. Please click the link below:");

  const link = document.createElement("a");

  link.href = checkoutUrl;
  link.target = "_blank";
  link.innerText = "Proceed to Checkout";
  link.style.marginTop = "20px";
  link.style.display = "block";
  link.style.color = "#1D80FA";
  link.style.textAlign = "center";

  document.querySelector("form")?.appendChild(link);
};

export const useCheckout = (): Pick<
  CheckoutFormProps,
  | "ticketsItems"
  | "ticketsCostsItems"
  | "total"
  | "discountCode"
  | "discountCodeValue"
  | "onSubmit"
> => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { eventOccurrenceId } = useParams();
  const { eventOccurrence } = useEventOccurrence();
  const { order, setOrder, removeOrder } = useOrderContext();

  const priceDisplay = eventOccurrence?.event.venue?.priceDisplay;
  const displayTotalOnly = areFeesIncluded(priceDisplay);

  return useMemo(() => {
    const {
      tickets,
      discountCode,
      subtotalAmount = 0,
      platformFeeSubtotalAmount = 0,
      venueFeeSubtotalAmount = 0,
      discountAmount,
      paymentFeeAmount = 0,
      totalAmount,
    } = order || {};

    const totalValueWithoutDiscount =
      subtotalAmount +
      platformFeeSubtotalAmount +
      venueFeeSubtotalAmount +
      paymentFeeAmount;

    return {
      ticketsItems: Object.values(
        groupBy(tickets?.nodes, "ticketPoolId")
      ).map<CheckoutTicketsItemProps>(([{ id, name }, ...restTickets]) => ({
        key: id,
        ticketType: name || "-",
        quantity: 1 + restTickets.length,
      })),
      ticketsCostsItems: displayTotalOnly
        ? [
            {
              key: "total",
              name: intl.formatMessage({
                defaultMessage: "Tickets",
              }),
              value: intl.formatNumber(totalValueWithoutDiscount || 0, {
                style: "currency",
                currency: environment.CURRENCY,
              }),
            },
          ]
        : [
            {
              key: "subtotal",
              name: intl.formatMessage({
                defaultMessage: "Tickets",
              }),
              value: intl.formatNumber(subtotalAmount || 0, {
                style: "currency",
                currency: environment.CURRENCY,
              }),
            },
            {
              key: "venue-fee",
              name: intl.formatMessage({
                defaultMessage: "Venue Fee",
              }),
              value: intl.formatNumber(
                (platformFeeSubtotalAmount || 0) +
                  (venueFeeSubtotalAmount || 0),
                {
                  style: "currency",
                  currency: environment.CURRENCY,
                }
              ),
            },
            {
              key: "payment-fee",
              name: intl.formatMessage({
                defaultMessage: "Credit Card Fee",
              }),
              value: intl.formatNumber(paymentFeeAmount || 0, {
                style: "currency",
                currency: environment.CURRENCY,
              }),
            },
          ],
      discountCode: discountCode?.code,
      discountCodeValue: intl.formatNumber(-(discountAmount || 0), {
        style: "currency",
        currency: environment.CURRENCY,
      }),
      total: intl.formatNumber(totalAmount || 0, {
        style: "currency",
        currency: environment.CURRENCY,
      }),
      async onSubmit() {
        try {
          const { checkoutUrl, status, token } =
            (await setOrder({
              status: OrderStatus.CHECKOUT,
            })) || {};

          if (status === OrderStatus.COMPLETED && token && eventOccurrenceId) {
            removeOrder();

            navigate(
              generatePath(ROUTE_PATHS.EVENT_SUCCESS, {
                eventOccurrenceId,
                orderToken: token,
              })
            );

            return undefined;
          }

          if (!checkoutUrl) {
            return {
              [FORM_ERROR]: intl.formatMessage({
                defaultMessage: "Unable to redirect to Payment",
              }),
            };
          }

          removeOrder();

          try {
            if (window.self !== window.top) {
              const newWindow = window.open(checkoutUrl, "_blank");

              if (!newWindow) {
                createFallbackLink(checkoutUrl);
              }
            } else {
              window.location.href = checkoutUrl;
            }
          } catch (e) {
            createFallbackLink(checkoutUrl);
          }

          return undefined;
        } catch (error) {
          return apolloErrorToSubmissionErrors(error);
        }
      },
    };
  }, [
    displayTotalOnly,
    eventOccurrenceId,
    intl,
    navigate,
    order,
    removeOrder,
    setOrder,
  ]);
};

export const useDiscountCodeForm = (): DiscountCodeFormProps => {
  const intl = useIntl();
  const { order, setOrder } = useOrderContext();

  const appliedDiscountCode = order?.discountCode?.code || null;

  return useMemo(
    () => ({
      discountCodePlaceholderLabel: intl.formatMessage({
        defaultMessage: "Discount Code",
        description: "Discount code placeholder",
      }),
      submitButtonLabel: appliedDiscountCode
        ? intl.formatMessage({
            defaultMessage: "Remove",
            description: "Discount code remove button label",
          })
        : intl.formatMessage({
            defaultMessage: "Apply",
            description: "Discount code apply button label",
          }),
      initialValues: {
        discountCode: appliedDiscountCode,
      },
      initialValuesEqual() {
        return true;
      },
      async onSubmit({ discountCode }) {
        try {
          await setOrder({
            discountCode: appliedDiscountCode ? null : discountCode,
          });

          return undefined;
        } catch (error) {
          return apolloErrorToSubmissionErrors(error);
        }
      },
    }),
    [intl, appliedDiscountCode, setOrder]
  );
};
