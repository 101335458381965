import { gql } from "@apollo/client";

export const FILE_FRAGMENT = gql`
  fragment File on File {
    id
    name
    type
    size
    url
  }
`;
