import { FieldWrapperProps } from "./types";
import * as Styled from "./styles";

const FieldWrapper: React.FC<FieldWrapperProps> = ({ error, controller }) => (
  <Styled.FieldWrapperLabel>
    {controller}
    {error && (
      <Styled.FieldWrapperError variant="typo5" as="span">
        {error}
      </Styled.FieldWrapperError>
    )}
  </Styled.FieldWrapperLabel>
);

export default FieldWrapper;
