import ReactSelect, { GroupBase } from "react-select";

import FieldWrapper from "../FieldWrapper";

import useCustomStyles from "./hooks";
import { SelectItem, SelectProps } from "./types";

const Select = <
  Option = SelectItem,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  $mode = "light",
  placeholder = "Select...",
  error,
  ...rest
}: SelectProps<Option, IsMulti, Group>): React.ReactElement => {
  const customStyles = useCustomStyles<Option, IsMulti, Group>($mode);

  return (
    <FieldWrapper
      {...{ error }}
      controller={
        <ReactSelect
          className="select"
          styles={customStyles}
          isSearchable={false}
          isClearable={false}
          maxMenuHeight={180}
          {...{ placeholder, ...rest }}
        />
      }
    />
  );
};

export default Select;
