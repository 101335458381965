import React from "react";
import EventTeaser from "components/molecules/EventTeaser";

import * as Styled from "./styles";
import { EventsListProps } from "./types";

const EventsList: React.FC<EventsListProps> = ({ items }) => (
  <Styled.EventsList>
    {items.map(({ key, ...item }) => (
      <Styled.EventsListItem {...{ key }}>
        <EventTeaser {...item} />
      </Styled.EventsListItem>
    ))}
  </Styled.EventsList>
);

export default EventsList;
