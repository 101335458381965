import styled from "styled-components";
import media from "theme/config/media";
import { Link } from "react-router-dom";

export const NavLink = styled(Link)`
  display: block;
  transition: ${({ theme }) => theme.transition};

  @media ${media.tablet} {
    &:hover {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;
