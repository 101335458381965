import { FakeFormProps } from "./types";
import { useFakeForm } from "./hooks";

const FakeForm: React.FC<FakeFormProps> = ({ children, onSubmit, ...rest }) => {
  const fakeForm = useFakeForm({ onSubmit });

  return (
    <div {...rest} {...fakeForm}>
      {children}
    </div>
  );
};

export default FakeForm;
