import Info from "../../../assets/svg/info.svg";

import * as Styled from "./styles";
import { TooltipProps } from "./types";

const Tooltip: React.FC<TooltipProps> = ({ label }) => (
  <Styled.Tooltip>
    <Info />
    <Styled.TooltipText>{label}</Styled.TooltipText>
  </Styled.Tooltip>
);

export default Tooltip;
