import * as styled from "styled-components";
import { normalize } from "styled-normalize";
import reset from "styled-reset";

export const GlobalStyles = styled.createGlobalStyle`
  ${normalize}
  ${reset}

  * {
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
  }
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.openSans};
    color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
    line-height: 1.4;
    width: 100%;
  }
  body {
    background-color: ${({ theme }) => theme.colors.mineShaft};
  }
  button {
    cursor: pointer;
  }
  a {
    color: currentColor;
    text-decoration: none;
  }
  svg {
    &:not([class]) {
      fill: currentColor;
    }
  }
`;
