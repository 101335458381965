import { useEffect, useState } from "react";

import { UseQuantityPickerValue } from "./types";

export const useQuantityPickerValue: UseQuantityPickerValue = ({
  value: propValue = 0,
  minValue = 0,
  maxValue,
  disabled,
  variant = "primary",
  onChange,
}) => {
  const [value, setValue] = useState(propValue);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  return {
    input: {
      value,
      disabled,
      onChange({ target: { valueAsNumber: nevValue } }) {
        if (Number.isNaN(nevValue)) {
          setValue(minValue);

          return;
        }

        setValue(nevValue);
        onChange?.(nevValue);
      },
    },
    decrementButton: {
      variant,
      disabled: disabled || value === minValue,
      onClick() {
        const nevValue = value - 1;

        setValue(nevValue);
        onChange?.(nevValue);
      },
    },
    incrementButton: {
      variant,
      disabled: disabled || value === maxValue,
      onClick() {
        const nevValue = value + 1;

        setValue(nevValue);
        onChange?.(nevValue);
      },
    },
  };
};
