import EventsList from "components/organisms/EventsList";
import Inner from "components/styles/Inner";
import Image from "components/atoms/Image";
import Select from "components/atoms/Fields/Select";
import { SelectItem } from "components/atoms/Fields/Select/types";
import { GroupBase } from "react-select";
import Skeleton from "components/atoms/Skeleton";

import * as Styled from "./styles";
import { EventsSectionProps } from "./types";

const EventsSection = <
  VenuePickerOption = SelectItem,
  VenuePickerIsMulti extends boolean = boolean,
  VenuePickerGroup extends GroupBase<VenuePickerOption> = GroupBase<VenuePickerOption>,
  MonthPickerOption = SelectItem,
  MonthPickerIsMulti extends boolean = boolean,
  MonthPickerGroup extends GroupBase<MonthPickerOption> = GroupBase<MonthPickerOption>
>({
  title,
  $heroBg,
  logoProps,
  venuePicker,
  monthPicker,
  items,
  loading,
  emptyMessage,
  errorMessage,
}: EventsSectionProps<
  VenuePickerOption,
  VenuePickerIsMulti,
  VenuePickerGroup,
  MonthPickerOption,
  MonthPickerIsMulti,
  MonthPickerGroup
>): React.ReactElement => (
  <Styled.EventsSection>
    <Inner>
      <Styled.EventsSectionHeader {...{ $heroBg }} $hasLogo={!!logoProps}>
        {logoProps && (
          <Styled.EventsSectionLogo>
            <Image {...logoProps} lazy />
          </Styled.EventsSectionLogo>
        )}
        <Styled.EventsSectionTitle as="h2" variant="heading1">
          {title}
        </Styled.EventsSectionTitle>
        <Styled.EventsSectionFilters>
          {venuePicker && (
            <Select placeholder="Select Venue" $mode="light" {...venuePicker} />
          )}
          {monthPicker && (
            <Select
              placeholder="Select Date"
              $mode="colored"
              {...monthPicker}
            />
          )}
        </Styled.EventsSectionFilters>
      </Styled.EventsSectionHeader>
      <Styled.EventsSectionMain>
        {loading && (
          <Styled.EventsSectionLoading>
            <Skeleton inline count={4} height={200} />
          </Styled.EventsSectionLoading>
        )}
        {emptyMessage && !loading && !items.length && !errorMessage && (
          <Styled.EventsSectionEmpty>{emptyMessage}</Styled.EventsSectionEmpty>
        )}
        {errorMessage && !loading && (
          <Styled.EventsSectionError>{errorMessage}</Styled.EventsSectionError>
        )}
        <EventsList {...{ items }} />
      </Styled.EventsSectionMain>
    </Inner>
  </Styled.EventsSection>
);

export default EventsSection;
