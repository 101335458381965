import { SessionInfoBarProps } from "components/SessionInfoBar/types";
import { useOrderContext } from "providers/OrderProvider/hooks";
import { useMemo } from "react";
import { useNavigate, useParams, generatePath } from "react-router-dom";
import { ROUTE_PATHS } from "setup/routePaths";
import { parseDate } from "utils/date";

const useSessionInfoBar = (): SessionInfoBarProps | undefined => {
  const { order, resetOrder } = useOrderContext();
  const navigate = useNavigate();
  const { eventOccurrenceId } = useParams();

  return useMemo<SessionInfoBarProps | undefined>(() => {
    const date = parseDate(order?.expireAt);

    if (!date || !eventOccurrenceId) {
      return undefined;
    }

    return {
      date,
      $isFixed: true,
      async onRestartSession() {
        await resetOrder();

        navigate(
          generatePath(ROUTE_PATHS.EVENT_DETAILS, { eventOccurrenceId })
        );
      },
    };
  }, [eventOccurrenceId, navigate, order?.expireAt, resetOrder]);
};

export default useSessionInfoBar;
