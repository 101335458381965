import Text from "components/styles/Typography";

import * as Styled from "./styles";
import { BadgeProps } from "./types";

const Badge: React.FC<BadgeProps> = ({ label }) => (
  <Styled.Badge>
    <Text variant="typo2" as="span">
      {label}
    </Text>
  </Styled.Badge>
);

export default Badge;
