import { makeMutationHook, makeQueryHook } from "utils/apolloUtils";

import { CREATE_EVENT, DELETE_EVENT, UPDATE_EVENT } from "./mutations";
import { CreateEvent, CreateEventVariables } from "./__generated__/CreateEvent";
import { GetEvents, GetEventsVariables } from "./__generated__/GetEvents";
import {
  GET_EVENTS,
  GET_EVENT,
  GET_EVENT_OCCURRENCES,
  GET_EVENT_OCCURRENCE,
} from "./queries";
import { GetEvent, GetEventVariables } from "./__generated__/GetEvent";
import { UpdateEvent, UpdateEventVariables } from "./__generated__/UpdateEvent";
import { DeleteEvent, DeleteEventVariables } from "./__generated__/DeleteEvent";
import {
  GetEventOccurrences,
  GetEventOccurrencesVariables,
} from "./__generated__/GetEventOccurrences";
import {
  GetEventOccurrence,
  GetEventOccurrenceVariables,
} from "./__generated__/GetEventOccurrence";

export const useEventCreateMutation = makeMutationHook<
  CreateEvent,
  CreateEventVariables
>(CREATE_EVENT);

export const useEventUpdateMutation = makeMutationHook<
  UpdateEvent,
  UpdateEventVariables
>(UPDATE_EVENT);

export const useEventDeleteMutation = makeMutationHook<
  DeleteEvent,
  DeleteEventVariables
>(DELETE_EVENT);

export const useEventListQuery = makeQueryHook<GetEvents, GetEventsVariables>(
  GET_EVENTS
);

export const useEventDetailsQuery = makeQueryHook<GetEvent, GetEventVariables>(
  GET_EVENT
);

export const useEventOccurrencesQuery = makeQueryHook<
  GetEventOccurrences,
  GetEventOccurrencesVariables
>(GET_EVENT_OCCURRENCES);

export const useEventOccurrenceQuery = makeQueryHook<
  GetEventOccurrence,
  GetEventOccurrenceVariables
>(GET_EVENT_OCCURRENCE);
