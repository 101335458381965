import FakeForm from "components/atoms/FakeForm";
import styled from "styled-components";

export const DiscountCodeForm = styled(FakeForm)`
  display: flex;
  width: 100%;
  margin: 1rem 0;
  justify-content: space-between;
`;

export const DiscountCodeInput = styled.div`
  flex-grow: 1;
`;

export const DiscountCodeButton = styled.div`
  display: flex;
  height: 2.75rem;
  margin-left: 0.75rem;
`;
