import { gql } from "@apollo/client";
import { ADDRESS_FRAGMENT } from "address/fragments";

import { ORGANIZATION_FRAGMENT } from "../organization";

import { VENUE_FRAGMENT } from "./fragments";

export const GET_VENUES = gql`
  ${VENUE_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${ORGANIZATION_FRAGMENT}
  query GetVenues(
    $take: Int
    $skip: Int
    $filters: VenueFilterInput
    $orderBy: [VenueOrderByInput!]
    $addressInclude: Boolean!
    $organizationInclude: Boolean!
  ) {
    venues(take: $take, skip: $skip, filters: $filters, orderBy: $orderBy) {
      count
      nodes {
        ...Venue
        address @include(if: $addressInclude) {
          ...Address
        }
        organization @include(if: $organizationInclude) {
          ...Organization
        }
      }
    }
  }
`;

export const GET_VENUE = gql`
  ${VENUE_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${ORGANIZATION_FRAGMENT}
  query GetVenue($id: Int!) {
    venue(id: $id) {
      ...Venue
      address {
        ...Address
      }
      organization {
        ...Organization
      }
    }
  }
`;
