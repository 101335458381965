import styled from "styled-components";
import { variant } from "styled-system";

import { StyledCheckboxProps } from "./types";

export const CheckboxWrapper = styled.div<StyledCheckboxProps>`
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  ${variant({
    variants: {
      primary: {
        color: "white",
      },
      secondary: {
        color: "black",
      },
    },
  })}
`;

export const CheckboxLabel = styled.label`
  width: 100%;
  display: flex;
  position: relative;
  cursor: pointer;
`;

export const FakeCheckbox = styled.span`
  position: relative;
  transition: ${({ theme }) => theme.transition};
  width: 1rem;
  height: 1rem;
  margin-top: 0.125rem;
  margin-right: 0.625rem;
  border: 2px solid currentColor;
  flex-shrink: 0;

  &::after {
    width: 0;
    height: 0;
    transition: inherit;
    background-color: transparent;
    content: "";
    transition: inherit;
    position: absolute;
    top: 0.125rem;
    left: 50%;
    transform: translateX(-50%) rotate(-45deg);
  }
`;

export const CheckboxInput = styled.input<StyledCheckboxProps>`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 0;
  height: 0;
  opacity: 0;

  &:checked + ${FakeCheckbox} {
    &::after {
      border-left: 1px solid;
      border-bottom: 1px solid;
      width: 60%;
      height: 30%;
      ${variant({
        variants: {
          primary: {
            borderColor: "white",
          },
          secondary: {
            borderColor: "black",
          },
        },
      })}
    }
  }
`;
