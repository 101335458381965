import React from "react";
import ReactDOM from "react-dom/client";
import App from "components/App";
import { TicketingPlatformWidget } from "types/window";

class TicketingPlatformError extends Error {
  name = "TicketingPlatformError";
}

const isArrayOfNumber = (value: unknown): value is number[] => {
  if (!Array.isArray(value)) {
    return false;
  }

  return value.every((item) => Number.isFinite(item));
};

const injectApp: TicketingPlatformWidget["injectApp"] = (
  containerId,
  venues
): void => {
  const container = window.document.getElementById(containerId);

  if (!container) {
    throw new TicketingPlatformError(`Container "${containerId}" not found`);
  }

  if (venues !== undefined && !isArrayOfNumber(venues)) {
    throw new TicketingPlatformError(
      '"venues" arg must be an array of numbers'
    );
  }

  const root = ReactDOM.createRoot(container);

  root.render(
    <React.StrictMode>
      <App {...{ venues }} />
    </React.StrictMode>
  );
};

export default {
  injectApp,
};
