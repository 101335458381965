import {
  GetEventOccurrences_eventOccurrences_nodes_event_presalePasswords_nodes,
  GetEventOccurrences_eventOccurrences_nodes_event_ticketPools_nodes,
} from "@ticketingplatform/api/dist/event/__generated__/GetEventOccurrences";
import { getSaleDate } from "utils/date";

export const getPresalesOptions = (
  presalePasswords:
    | GetEventOccurrences_eventOccurrences_nodes_event_presalePasswords_nodes[]
    | undefined,
  ticketPools:
    | GetEventOccurrences_eventOccurrences_nodes_event_ticketPools_nodes[]
    | undefined,
  date: AWSDateTime
) => {
  if (
    !presalePasswords ||
    !presalePasswords.length ||
    !ticketPools ||
    !ticketPools.length
  ) {
    return undefined;
  }

  let earliestTicketsAvailable = new Date();
  let ticketsAvailableNow = false;

  ticketPools.forEach(({ availableBeforeDays, availableBeforeTime }) => {
    if (!availableBeforeDays || !availableBeforeTime) {
      ticketsAvailableNow = true;

      return;
    }

    const saleDate = getSaleDate(
      new Date(date.toString()),
      availableBeforeDays,
      availableBeforeTime
    );

    if (saleDate < earliestTicketsAvailable) {
      earliestTicketsAvailable = saleDate;
    }
  });

  const currentDate = new Date();

  const isAvailableNow =
    ticketsAvailableNow || currentDate > earliestTicketsAvailable;

  const isPresalePasswordsAvailable = presalePasswords.some(
    ({ expireAt }) => currentDate <= new Date(expireAt.toString())
  );

  return {
    earliestTicketsAvailable,
    isAvailableNow,
    isPresalePasswordsAvailable,
  };
};
