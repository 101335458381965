import styled from "styled-components";

export const VideoIframe = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

export const VideoWrapper = styled.div`
  [style*="--aspect-ratio"] {
    position: relative;
  }

  [style*="--aspect-ratio"] ${VideoIframe} {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
  }

  @supports not (aspect-ratio: 1/1) {
    [style*="--aspect-ratio"]::before {
      height: 0;
      padding-bottom: calc(100% / (var(--aspect-ratio)));
    }
  }
  @supports (aspect-ratio: 1/1) {
    [style*="--aspect-ratio"]::before {
      aspect-ratio: calc(var(--aspect-ratio));
    }
  }
`;
