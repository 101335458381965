import { gql } from "@apollo/client";
import { TICKET_AGGREGATION_FRAGMENT } from "ticket/fragments/ticketAggregation";

export const EVENT_TICKET_AGGREGATION_FRAGMENT = gql`
  ${TICKET_AGGREGATION_FRAGMENT}
  fragment EventTicketsAggregation on EventTicketAggregation {
    availableCount
    tickets {
      ...TicketAggregation
    }
  }
`;
