import { gql } from "@apollo/client";

export const DISCOUNT_CODE_FRAGMENT = gql`
  fragment DiscountCode on DiscountCode {
    id
    name
    code
    amount
    maxUse
    expireAt
    isApplicableToAllEvents
    numberOfTicketsAppliedInOrder
    venueId
    createdAt
    updatedAt
  }
`;
