import Button from "components/atoms/Button";

import { SummaryBarProps } from "./types";
import * as Styled from "./styles";

const SummaryBar: React.FC<SummaryBarProps> = ({
  quantity,
  quantityLabel,
  total,
  totalLabel,
  buttonLabel,
  buttonType,
  ...rest
}) => (
  <Styled.SummaryBar>
    <Styled.SummaryBarText>
      {quantityLabel}: {quantity}
    </Styled.SummaryBarText>
    <Styled.SummaryBarAction>
      <Styled.SummaryBarText>
        {totalLabel}: {total}
      </Styled.SummaryBarText>
      <Button $mode="primary" $size="big" type={buttonType} {...rest}>
        {buttonLabel}
      </Button>
    </Styled.SummaryBarAction>
  </Styled.SummaryBar>
);

export default SummaryBar;
