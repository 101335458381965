import { gql } from "@apollo/client";

export const ADDRESS_FRAGMENT = gql`
  fragment Address on Address {
    address1
    address2
    city
    country
    countryCode
    createdAt
    id
    province
    provinceCode
    updatedAt
    zip
  }
`;
