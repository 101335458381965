import styled, { css } from "styled-components";
import media from "theme/config/media";

import { StyledEventHeadingProps } from "./types";

export const EventHeading = styled.div<StyledEventHeadingProps>`
  margin-bottom: 2rem;
  text-align: center;

  @media ${media.tablet} {
    ${({ $isLeftOnDesktop }) =>
      $isLeftOnDesktop &&
      css`
        text-align: left;
      `}
  }
`;
