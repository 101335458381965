import { PriceDisplay } from "@ticketingplatform/api/dist/__generated__/globalTypes";
import { GetEvent_event_ticketPools_nodes } from "@ticketingplatform/api/dist/event/__generated__/GetEvent";
import { Venue } from "@ticketingplatform/api/dist/venue/__generated__/Venue";

export const areFeesIncluded = (priceDisplay?: Venue["priceDisplay"]) =>
  priceDisplay === PriceDisplay.ALL_FEES;

export const getPriceToDisplay = (
  ticketPool: Pick<GetEvent_event_ticketPools_nodes, "price" | "priceWithFees">,
  priceDisplay?: Venue["priceDisplay"]
) => {
  const { priceWithFees, price } = ticketPool || {};

  return areFeesIncluded(priceDisplay) ? priceWithFees : price;
};
