import { gql } from "@apollo/client";

import { EVENT_WITH_RELATIONS_FRAGMENT } from "./eventWithRelations";
import { EVENT_OCCURRENCE_FRAGMENT } from "./eventOccurrence";
import { EVENT_TICKET_AGGREGATION_FRAGMENT } from "./eventTicketsAggregation";

export const EVENT_OCCURRENCE_WITH_RELATIONS_FRAGMENT = gql`
  ${EVENT_WITH_RELATIONS_FRAGMENT}
  ${EVENT_OCCURRENCE_FRAGMENT}
  ${EVENT_TICKET_AGGREGATION_FRAGMENT}
  fragment EventOccurrenceWithRelations on EventOccurrence {
    ...EventOccurrence
    event @include(if: $eventInclude) {
      ...EventWithRelations
    }
    ticketsAggregation @include(if: $ticketsAggregationInclude) {
      ...EventTicketsAggregation
    }
  }
`;
