import styled from "styled-components";
import media from "theme/config/media";

export const CheckoutSection = styled.section`
  margin: 1.5rem 0 9rem 0;

  @media ${media.tablet} {
    margin-top: 2rem;
  }
`;

export const CheckoutWrapper = styled.div`
  @media ${media.tablet} {
    margin: 0 auto;
    max-width: 30rem;
  }
`;
