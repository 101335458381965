import { gql } from "@apollo/client";

export const ORDER_FRAGMENT = gql`
  fragment Order on Order {
    id
    firstName
    lastName
    email
    phoneNumber
    termsAgree
    newsletterAgree
    customerNotes
    status
    discountCodeId
    subtotalAmount
    discountAmount
    platformFeeSubtotalAmount
    platformFeeAmount
    venueFeeSubtotalAmount
    venueFeeAmount
    paymentFeeAmount
    totalAmount
    expireAt
    checkoutUrl
    orderNumber
    createdAt
    updatedAt
    paymentMethod
    receiptUrl
    token
    paymentTerminalId
    paymentType
    sendReceipt
    autoCheckIn
  }
`;
