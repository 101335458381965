import { Field } from "react-final-form";

import { CheckboxFieldProps } from "./types";

import Checkbox from ".";

const CheckboxField: React.FC<CheckboxFieldProps> = ({ name, ...props }) => (
  <Field
    {...{ name }}
    type="checkbox"
    render={({
      input,
      meta: { touched, modifiedSinceLastSubmit, error, submitError },
    }) => (
      <Checkbox
        error={touched && !modifiedSinceLastSubmit && (error || submitError)}
        {...input}
        {...props}
      />
    )}
  />
);

export default CheckboxField;
