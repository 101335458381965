export const getEnvVar = (name: string): string | undefined =>
  process.env[name];

export const getRequiredEnvVar = (name: string): string => {
  const value = getEnvVar(name);

  if (!value) {
    throw new Error(`Missing environment variable - ${name}`);
  }

  return value;
};
