import { Form, Field } from "react-final-form";
import Button from "components/atoms/Button";
import InputField from "components/atoms/Fields/Input/InputField";
import Text from "components/styles/Typography";

import * as Styled from "./styles";
import { PresalePasswordInputProps } from "./types";

const wrongPassword = "Wrong password";
const noPassword = "Please provide a password";

const PresalePasswordInput: React.FC<PresalePasswordInputProps> = ({
  passwords = [],
  presalePassword,
  setPresalePassword,
}) => {
  if (passwords.length === 0 || presalePassword) {
    return null;
  }

  return (
    <Styled.PresalePasswordInputComponent>
      <Text variant="typo2" mb="0.25rem">
        Enter the password to access the presale.
      </Text>
      <Form
        initialValues={{ password: "" }}
        onSubmit={({ password }) => {
          // FIXME: security issue! should check password on backend
          if (passwords.includes(password)) {
            setPresalePassword(password);
          }
        }}
        validate={(values) => {
          const errors: { password?: string } = {};

          if (!values.password) {
            errors.password = noPassword;
          } else if (!passwords.includes(values.password)) {
            errors.password = wrongPassword;
          }

          return errors;
        }}
        render={({ handleSubmit }) => (
          <Styled.PresalePasswordInputRow>
            <Field name="password">
              {({ input }) => (
                <InputField {...input} placeholder="" autoComplete="off" />
              )}
            </Field>
            <Button $mode="primary" onClick={handleSubmit}>
              Enter
            </Button>
          </Styled.PresalePasswordInputRow>
        )}
      />
    </Styled.PresalePasswordInputComponent>
  );
};

export default PresalePasswordInput;
