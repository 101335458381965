import isValid from "date-fns/isValid";
import format from "date-fns/format";
import subMinutes from "date-fns/subMinutes";
import subMilliseconds from "date-fns/subMilliseconds";
import startOfMonth from "date-fns/fp/startOfMonth";
import endOfMonth from "date-fns/fp/endOfMonth";
import setYear from "date-fns/fp/setYear";
import setMonth from "date-fns/fp/setMonth";
import formatInTimeZone from "date-fns-tz/formatInTimeZone";
import getTimezoneOffset from "date-fns-tz/getTimezoneOffset";
import compose from "lodash/fp/compose";
import setHours from "date-fns/fp/setHours";
import setMinutes from "date-fns/fp/setMinutes";
import subDays from "date-fns/subDays";

const DATE_TIME_FORMAT = "EEEE MMM d 'at' hh:mmaaa";
const DATE_MONTH_FORMAT = "MMMM yyyy";

export const dateToUTC = (date: Date): Date =>
  subMinutes(date, date.getTimezoneOffset());

export const parseDate = (value?: unknown): Date | undefined => {
  if (!value) {
    return undefined;
  }

  if (value instanceof Date) {
    return value;
  }

  if (typeof value === "string" || typeof value === "number") {
    const dateValue = new Date(value);

    if (!isValid(dateValue)) {
      throw new Error(`Invalid date value: "${value}"`);
    }

    return dateValue;
  }

  return undefined;
};

export const formatDateTime = (
  value?: unknown | null,
  timezone?: string | null
): string => {
  const date = parseDate(value);

  if (!date) {
    return "";
  }

  if (!timezone) {
    return format(date, DATE_TIME_FORMAT);
  }

  return formatInTimeZone(
    subMilliseconds(date, getTimezoneOffset(timezone, date)),
    timezone,
    DATE_TIME_FORMAT
  );
};

export const formatDateMonth = (
  value?: unknown | null,
  timezone?: string | null
): string => {
  const date = parseDate(value);

  if (!date) {
    return "";
  }

  if (!timezone) {
    return format(date, DATE_MONTH_FORMAT);
  }

  return formatInTimeZone(date, timezone, DATE_MONTH_FORMAT);
};

export const getStartOfMonth = (month: number, year: number): Date =>
  compose(setYear(year), setMonth(month), startOfMonth)(new Date(0));

export const getEndOfMonth = (month: number, year: number): Date =>
  compose(setYear(year), setMonth(month), endOfMonth)(new Date(0));

export const getStartOfMonthUTC = (month: number, year: number): Date =>
  dateToUTC(getStartOfMonth(month, year));

export const getEndOfMonthUTC = (month: number, year: number): Date =>
  dateToUTC(getEndOfMonth(month, year));

export const setDateTime = (date: Date, time: string) => {
  const [hours, minutes] = time
    ?.split(":")
    .map((value) => Number.parseInt(value, 10)) || [0, 0];

  return compose(setHours(hours), setMinutes(minutes))(date);
};

export const getSaleDate = (date: Date, days: number, time: string) =>
  dateToUTC(setDateTime(subDays(date, days), time));

export const zonedTimeToLocal = (date: Date, timezone?: string | null) => {
  if (!timezone) {
    return date;
  }

  const offset = getTimezoneOffset(timezone);

  return subMilliseconds(date, offset);
};
