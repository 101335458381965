import styled from "styled-components";

export const Badge = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  background-color: ${({ theme }) => theme.colors.dodgerBlue};
  color: ${({ theme }) => theme.colors.white};
  min-height: 2rem;
  text-transform: uppercase;
  border-radius: ${({ theme }) => theme.radii.default};
  line-height: 1;
`;
