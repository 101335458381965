import styled, { css } from "styled-components";
import media from "theme/config/media";

import { StyledNavItemProps } from "./types";

export const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
`;

export const NavButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @media ${media.tablet} {
    display: none;
  }
`;

export const NavButtonPrev = styled(NavButton)`
  left: 0.75rem;
`;

export const NavButtonClose = styled(NavButton)`
  right: 0.75rem;
`;

export const NavList = styled.ul`
  display: flex;
  justify-content: center;
  margin: 0 3.5rem;
  width: 100%;

  @media ${media.tablet} {
    margin: 0;
  }
`;

export const NavItem = styled.li<StyledNavItemProps>`
  display: ${({ $isActive }) => ($isActive ? "flex" : "none")};
  color: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.white : theme.colors.emperor};

  @media ${media.tablet} {
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:first-child) {
      &::before {
        display: block;
        content: "";
        width: 3rem;
        height: 1px;
        margin: 0 1rem;
        background-color: ${({ theme }) => theme.colors.emperor};
      }
    }
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      a {
        pointer-events: none;
      }
    `}
`;
