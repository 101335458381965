import { useMemo } from "react";
import SuccessSection from "@ticketingplatform/ui/dist/components/sections/Checkout/SuccessMessage";
import Header from "@ticketingplatform/ui/dist/components/organisms/Header";
import useEventHeading from "hooks/useEventHeading";

const DEFAULT_SUCCESS_MESSAGE =
  "Thank you. You will receive an order confirmation email related to your order and tickets.";

const SuccessPage: React.FC = () => {
  const eventHeading = useEventHeading();

  const successMessage: string = useMemo(() => {
    if (!eventHeading || eventHeading?.loading) {
      return "";
    }

    if (eventHeading.sendTicketsAt) {
      const currentDate = new Date();
      const sendTicketsDate = eventHeading.sendTicketsAt;

      if (currentDate < sendTicketsDate) {
        return "Thank you. You should receive an email confirmation. Another email with your QR code will be sent at a later date.";
      }
    }

    return DEFAULT_SUCCESS_MESSAGE;
  }, [eventHeading]);

  return (
    <>
      <Header />
      <SuccessSection successMessage={successMessage} {...{ eventHeading }} />
    </>
  );
};

export default SuccessPage;
