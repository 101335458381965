import styled, { css } from "styled-components";
import { variant } from "styled-system";

import { INPUT_VARIANTS } from "./consts";
import { StyledInputProps } from "./types";

export const inputBaseStyles = css<StyledInputProps>`
  font-size: 0.875rem;
  outline: none;
  border: 2px solid ${({ theme }) => theme.colors.shark};
  border-radius: 0;
  font-weight: ${({ theme }) => theme.fontWeights.regular};

  &::placeholder {
    opacity: 1;
    color: ${({ theme }) => theme.colors.white};
  }

  ${variant({
    variants: INPUT_VARIANTS,
  })}

  ${({ theme, error }) =>
    error &&
    css`
      color: currentColor;
      border-color: ${theme.colors.error};
    `}

  ${({ theme, success }) =>
    success &&
    css`
      border-color: ${theme.colors.success};
    `}
`;

export const Input = styled.input<StyledInputProps>`
  width: 100%;
  height: 2.75rem;

  ${inputBaseStyles}
`;
