import { gql } from "@apollo/client";

import { DISCOUNT_CODE_NODE_FRAGMENT } from "./fragments/discountCodeNode";

export const DISCOUNT_CODES_QUERY = gql`
  ${DISCOUNT_CODE_NODE_FRAGMENT}
  query DiscountCodes(
    $skip: Int
    $take: Int
    $filters: DiscountCodeFilterInput
    $orderBy: [DiscountCodeOrderByInput!]
    $eventsSkip: Int
    $eventsTake: Int
    $eventsFilters: EventFilterInput
    $eventsOrderBy: [EventOrderByInput!]
  ) {
    discountCodes(
      skip: $skip
      take: $take
      filters: $filters
      orderBy: $orderBy
    ) {
      nodes {
        ...DiscountCodeNode
      }
      count
    }
  }
`;

export const DISCOUNT_CODE_QUERY = gql`
  ${DISCOUNT_CODE_NODE_FRAGMENT}
  query DiscountCodeQuery(
    $id: Int!
    $eventsSkip: Int
    $eventsTake: Int
    $eventsFilters: EventFilterInput
    $eventsOrderBy: [EventOrderByInput!]
  ) {
    discountCode(id: $id) {
      ...DiscountCodeNode
    }
  }
`;
