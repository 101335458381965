import styled from "styled-components";
import media from "theme/config/media";

export const SummaryBar = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  padding: 0.375rem 0.75rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${({ theme }) => theme.sizes.summaryBarHeight};

  @media ${media.phone} {
    padding-left: ${({ theme }) => theme.sizes.defaultPadding};
    padding-right: ${({ theme }) => theme.sizes.defaultPadding};
  }

  @media ${media.tablet} {
    justify-content: center;
  }
`;

export const SummaryBarText = styled.p`
  margin-right: 0.75rem;
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  @media ${media.tablet} {
    font-size: 1.125rem;
    margin-right: 1.25rem;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
  }
`;

export const SummaryBarAction = styled.div`
  display: flex;
  align-items: center;

  @media ${media.tablet} {
    margin-left: 8rem;
  }
`;
