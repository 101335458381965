import { PropsWithChildren } from "react";

import { OrderContext } from "./context";
import { useOrderContextValue } from "./hooks";

const OrderProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const value = useOrderContextValue();

  return (
    <OrderContext.Provider {...{ value }}>{children}</OrderContext.Provider>
  );
};

export default OrderProvider;
