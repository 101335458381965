import { gql } from "@apollo/client";

import { ORGANIZATION_FRAGMENT } from "../organization";
import { ADDRESS_FRAGMENT } from "../address/fragments";

import { VENUE_FRAGMENT } from "./fragments";

export const CREATE_VENUE = gql`
  ${VENUE_FRAGMENT}
  ${ORGANIZATION_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  mutation CreateVenue($input: VenueCreateInput!) {
    venueCreate(input: $input) {
      ...Venue
      address {
        ...Address
      }
      organization {
        ...Organization
      }
    }
  }
`;

export const UPDATE_VENUE = gql`
  ${VENUE_FRAGMENT}
  ${ORGANIZATION_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  mutation UpdateVenue($id: Int!, $input: VenueUpdateInput!) {
    venueUpdate(id: $id, input: $input) {
      ...Venue
      address {
        ...Address
      }
      organization {
        ...Organization
      }
    }
  }
`;

export const DELETE_VENUE = gql`
  mutation DeleteVenue($id: Int!) {
    venueDelete(id: $id)
  }
`;
