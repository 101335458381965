import styled from "styled-components";
import media from "theme/config/media";

export const EventDetailsSection = styled.section`
  margin-top: -1px;
  margin-bottom: 9rem;

  @media ${media.tablet} {
    margin-top: 2rem;
  }
`;

export const EventDetailsSectionBody = styled.div`
  display: grid;
  grid-column-gap: 3rem;
  grid-row-gap: 1.5rem;

  @media ${media.tablet} {
    grid-template-columns: 1fr 0.75fr;
    grid-column-gap: 7rem;
  }
`;

export const EventDetailsSectionMedia = styled.div`
  grid-row: 1;
  grid-column: 1;
  margin-left: -${({ theme }) => theme.sizes.defaultPadding};
  margin-right: -${({ theme }) => theme.sizes.defaultPadding};

  @media ${media.tablet} {
    margin: 0;
  }
`;

export const EventDetailsSectionWysiwyg = styled.div`
  grid-row: 4;
  grid-column: 1;

  @media ${media.tablet} {
    grid-row: 2;
  }
`;

export const EventDetailsSectionVideo = styled.div`
  grid-row: 5;
  grid-column: 1;

  @media ${media.tablet} {
    grid-row: 3;
  }
`;

export const EventDetailsSectionTickets = styled.div`
  grid-row: 2;
  grid-column: 1;
  margin-bottom: 1rem;

  @media ${media.tablet} {
    grid-column: 2;
    grid-row: 1/3;
    margin-bottom: 0;
  }
`;

export const EventDetailsSectionSeatMap = styled.div`
  grid-row: 3;
  grid-column: 1;
  margin-bottom: 1rem;

  @media ${media.tablet} {
    grid-column: 2;
    margin-bottom: 0;
    max-width: 20rem;
  }
`;
