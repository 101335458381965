import styled from "styled-components";

import { StyledWrapperProps } from "./types";

export const ImageFigure = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin: 0;

  img {
    display: block;
    max-width: 100%;
  }
`;

export const Wrapper = styled.div<StyledWrapperProps>`
  [style*="--aspect-ratio"] {
    position: relative;
  }

  [style*="--aspect-ratio"] ${ImageFigure} {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  [style*="--aspect-ratio"] img {
    object-fit: ${({ fit }) => fit ?? "cover"};
    object-position: center;
    height: 100%;
    width: 100%;
  }

  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    width: 100%;
  }

  @supports not (aspect-ratio: 1/1) {
    [style*="--aspect-ratio"]::before {
      height: 0;
      padding-bottom: calc(100% / (var(--aspect-ratio)));
    }
  }
  @supports (aspect-ratio: 1/1) {
    [style*="--aspect-ratio"]::before {
      aspect-ratio: calc(var(--aspect-ratio));
    }
  }
`;

export const ImagePlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;
