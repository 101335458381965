import styled from "styled-components";
import { Link } from "react-router-dom";
import media from "theme/config/media";

export const EventTeaserBox = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radii.default};
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: ${({ theme }) => theme.transition};
  }

  @media ${media.tablet} {
    &:hover {
      &::after {
        background-color: rgba(0, 0, 0, 0.35);
      }
    }
  }
`;

export const EventPresaleOverlay = styled.div`
  z-index: 2;
  display: block;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5);
`;

export const EventTeaserLink = styled(Link)`
  z-index: 3;
  display: block;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const EventTeaserMain = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.75rem;
  z-index: 1;
`;

export const EventTeaserAgeLimit = styled.div`
  margin-right: 5rem;
`;

export const EventTeaserBadge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const EventTeaserBottom = styled.div``;
