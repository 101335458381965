const buttonVariantsCommonStyles = {
  color: "white",
  fontWeight: "semiBold",
  borderRadius: "default",
  fontSize: "0.875rem",
  padding: "0.125rem 1.75rem",
};

export const buttonModes = {
  primary: {
    ...buttonVariantsCommonStyles,
    backgroundColor: "dodgerBlue",
    "&:hover": {
      backgroundColor: ["", "", "cornflowerBlue"],
    },
  },
  secondary: {
    ...buttonVariantsCommonStyles,
    backgroundColor: "cornflowerBlue",
    "&:hover": {
      backgroundColor: ["", "", "dodgerBlue"],
    },
  },
  tertiary: {
    ...buttonVariantsCommonStyles,
    backgroundColor: "blueDianne",
    "&:hover": {
      backgroundColor: ["", "", "deepSeaDive"],
    },
  },
  bordered: {
    ...buttonVariantsCommonStyles,
    border: "2px solid",
    borderColor: "dodgerBlue",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: ["", "", "dodgerBlue"],
    },
  },
  simple: {
    border: 0,
    backgroundColor: "transparent",
    color: "white",
    fontSize: "1.25rem",
    "&:hover": {
      color: ["", "", "iron"],
    },
  },
  link: {
    display: "inline",
    color: "inherit",
    fontWeight: "bold",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
};

export const buttonSizes = {
  default: {
    padding: "0.25rem",
  },
  medium: {
    minHeight: "2.25rem",
  },
  big: {
    minHeight: "2.625rem",
  },
};
