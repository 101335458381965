import { makeMutationHook, makeQueryHook } from "utils/apolloUtils";

import {
  ORDERS_EXPORT_MUTATION,
  ORDER_CREATE_MUTATION,
  ORDER_UPDATE_MUTATION,
} from "./mutations";
import {
  ORDER_LIST_QUERY,
  ORDER_BY_TOKEN_QUERY,
  ORDER_READ_QUERY,
} from "./queries";
import { OrderCreate, OrderCreateVariables } from "./__generated__/OrderCreate";
import { OrderUpdate, OrderUpdateVariables } from "./__generated__/OrderUpdate";
import { OrderList, OrderListVariables } from "./__generated__/OrderList";
import { OrderRead, OrderReadVariables } from "./__generated__/OrderRead";
import {
  OrderByToken,
  OrderByTokenVariables,
} from "./__generated__/OrderByToken";
import {
  OrdersExport,
  OrdersExportVariables,
} from "./__generated__/OrdersExport";

export const useOrderCreateMutation = makeMutationHook<
  OrderCreate,
  OrderCreateVariables
>(ORDER_CREATE_MUTATION);

export const useOrderUpdateMutation = makeMutationHook<
  OrderUpdate,
  OrderUpdateVariables
>(ORDER_UPDATE_MUTATION);

export const useOrdersExportMutation = makeMutationHook<
  OrdersExport,
  OrdersExportVariables
>(ORDERS_EXPORT_MUTATION);

export const useOrderListQuery = makeQueryHook<OrderList, OrderListVariables>(
  ORDER_LIST_QUERY
);

export const useOrderReadQuery = makeQueryHook<OrderRead, OrderReadVariables>(
  ORDER_READ_QUERY
);

export const useOrderByTokenQuery = makeQueryHook<
  OrderByToken,
  OrderByTokenVariables
>(ORDER_BY_TOKEN_QUERY);
