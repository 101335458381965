import Image from "components/atoms/Image";

import * as Styled from "./styles";
import { VideoPlaceholderProps } from "./types";

const VideoPlaceholder: React.FC<VideoPlaceholderProps> = ({
  onClick,
  ...rest
}) => (
  <Styled.VideoPlaceholder>
    <Image {...rest} />
    <Styled.VideoPlaceholderButton
      aria-label="Play video"
      {...{ onClick }}
      type="button"
    >
      <Styled.VideoPlaceholderIcon />
    </Styled.VideoPlaceholderButton>
  </Styled.VideoPlaceholder>
);

export default VideoPlaceholder;
