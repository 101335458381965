import { gql } from "@apollo/client";

export const PRESALE_PASSWORD_FRAGMENT = gql`
  fragment PresalePassword on PresalePassword {
    id
    name
    password
    expireAt
    eventId
    createdAt
    updatedAt
  }
`;
