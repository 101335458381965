import styled from "styled-components";
import media from "theme/config/media";

export const EventDetails = styled.div`
  color: ${({ theme }) => theme.colors.white};
`;

export const EventDetailsBody = styled.div`
  @media ${media.tablet} {
    max-width: 20rem;
  }
`;

export const EventDetailsFooter = styled.div`
  margin-top: 1rem;

  @media ${media.smallPhone} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
  }

  @media ${media.tablet} {
    max-width: 20rem;
  }
`;

export const EventDetailsCheckbox = styled.div`
  display: flex;
`;

export const EventDetailsTooltip = styled.span`
  margin-left: 0.25rem;
  color: ${({ theme }) => theme.colors.tiara};
`;
