import Button, { ButtonLink } from "components/atoms/Button";
import { useLocation } from "react-router-dom";

import ArrowLeft from "../../../../../assets/svg/arrow-left.svg";
import Close from "../../../../../assets/svg/close.svg";
import NavItem from "../NavItem";

import * as Styled from "./styles";
import { NavProps } from "./types";

const Nav: React.FC<NavProps> = ({
  items,
  href,
  withCloseIcon,
  closeHref,
  onClose,
}) => {
  const { pathname } = useLocation();

  return (
    <Styled.Nav>
      {href && (
        <Styled.NavButtonPrev>
          <ButtonLink {...{ href }} $mode="simple" $size="default">
            <ArrowLeft />
          </ButtonLink>
        </Styled.NavButtonPrev>
      )}
      <Styled.NavList>
        {items.map(
          ({ key, href: navItemHref, label, $isActive, $isDisabled }) => (
            <Styled.NavItem
              {...{ key, $isDisabled }}
              $isActive={$isActive ?? pathname === navItemHref}
            >
              <NavItem
                {...{ label }}
                $isActive={$isActive ?? pathname === navItemHref}
                href={navItemHref}
              />
            </Styled.NavItem>
          )
        )}
      </Styled.NavList>
      {withCloseIcon && (
        <Styled.NavButtonClose
          {...(closeHref
            ? {
                as: "a",
                href: closeHref,
              }
            : {})}
        >
          <Button
            onClick={onClose}
            $mode="simple"
            $size="default"
            aria-label="Close"
          >
            <Close />
          </Button>
        </Styled.NavButtonClose>
      )}
    </Styled.Nav>
  );
};

export default Nav;
