import { ButtonLink } from "components/atoms/Button";

import Checkout from "..";

import * as Styled from "./styles";
import { ErrorMessageProps } from "./types";

const ErrorSection: React.FC<ErrorMessageProps> = ({
  eventHeading,
  errorMessage,
  button,
}) => (
  <Checkout {...{ eventHeading }}>
    {typeof errorMessage === "string" ? (
      <Styled.ErrorMessage variant="typo1">{errorMessage}</Styled.ErrorMessage>
    ) : (
      <Styled.ErrorMessage as="div" variant="typo1">
        {errorMessage}
      </Styled.ErrorMessage>
    )}
    {button && (
      <Styled.ErrorMessageButton>
        <ButtonLink $size="big" {...button}>
          {button.label}
        </ButtonLink>
      </Styled.ErrorMessageButton>
    )}
  </Checkout>
);

export default ErrorSection;
