import { Field } from "react-final-form";

import { InputFieldProps } from "./types";

import Input from ".";

const InputField: React.FC<InputFieldProps> = ({ name, ...props }) => (
  <Field
    {...{ name }}
    render={({
      input,
      meta: { touched, modifiedSinceLastSubmit, error, submitError },
    }) => (
      <Input
        error={touched && !modifiedSinceLastSubmit && (error || submitError)}
        {...input}
        {...props}
      />
    )}
  />
);

export default InputField;
