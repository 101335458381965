import Header from "@ticketingplatform/ui/dist/components/organisms/Header";
import { useIntl } from "react-intl";
import ErrorSection from "@ticketingplatform/ui/dist/components/sections/Checkout/ErrorMessage";
import useEventHeading from "hooks/useEventHeading";
import { ROUTE_PATHS } from "setup/routePaths";

const ErrorPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const eventHeading = useEventHeading();

  return (
    <>
      <Header />
      <ErrorSection
        errorMessage={formatMessage({
          defaultMessage: "Your payment did not succeed.",
          description: "Error message",
        })}
        button={{
          href: ROUTE_PATHS.EVENT_LIST,
          label: formatMessage({
            defaultMessage: "Start over",
            description: "Button label",
          }),
        }}
        {...{ eventHeading }}
      />
    </>
  );
};

export default ErrorPage;
