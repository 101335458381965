import Text from "components/styles/Typography";

import * as Styled from "./styles";
import { NavItemProps } from "./types";

const NavItem: React.FC<NavItemProps> = ({ label, href, $isActive }) =>
  href && !$isActive ? (
    <Styled.NavLink to={href}>
      <Text as="span" variant="heading2">
        {label}
      </Text>
    </Styled.NavLink>
  ) : (
    <Text as="span" variant="heading2">
      {label}
    </Text>
  );

export default NavItem;
