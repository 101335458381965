import { gql } from "@apollo/client";

import { ORDER_WITH_RELATIONS_FRAGMENT } from "./fragments/orderWithRelations";

export const ORDER_BY_TOKEN_QUERY = gql`
  ${ORDER_WITH_RELATIONS_FRAGMENT}
  query OrderByToken(
    $token: String!
    $ticketsInclude: Boolean = false
    $ticketsSkip: Int
    $ticketsTake: Int
    $ticketsFilters: TicketFilterInput
    $ticketsOrderBy: [TicketOrderByInput!]
    $discountCodeInclude: Boolean = false
    $ticketPoolInclude: Boolean = false
    $eventInclude: Boolean = false
    $refundsInclude: Boolean = false
    $refundsSkip: Int
    $refundsTake: Int
    $fileInclude: Boolean = false
    $refundsFilters: RefundFilterInput
    $refundsOrderBy: [RefundOrderByInput!]
    $orderInclude: Boolean = false
  ) {
    orderByToken(token: $token) {
      ...OrderWithRelations
    }
  }
`;

export const ORDER_READ_QUERY = gql`
  ${ORDER_WITH_RELATIONS_FRAGMENT}
  query OrderRead(
    $id: Int!
    $ticketsInclude: Boolean = false
    $ticketsSkip: Int
    $ticketsTake: Int
    $ticketsFilters: TicketFilterInput
    $ticketsOrderBy: [TicketOrderByInput!]
    $discountCodeInclude: Boolean = false
    $ticketPoolInclude: Boolean = false
    $eventInclude: Boolean = false
    $refundsInclude: Boolean = false
    $refundsSkip: Int
    $refundsTake: Int
    $fileInclude: Boolean = false
    $refundsFilters: RefundFilterInput
    $refundsOrderBy: [RefundOrderByInput!]
    $orderInclude: Boolean = false
  ) {
    order(id: $id) {
      ...OrderWithRelations
    }
  }
`;

export const ORDER_LIST_QUERY = gql`
  ${ORDER_WITH_RELATIONS_FRAGMENT}
  query OrderList(
    $take: Int
    $skip: Int
    $filters: OrderFilterInput
    $orderBy: [OrderOrderByInput!]
    $ticketsInclude: Boolean = false
    $ticketsSkip: Int
    $ticketsTake: Int
    $ticketsFilters: TicketFilterInput
    $ticketsOrderBy: [TicketOrderByInput!]
    $discountCodeInclude: Boolean = false
    $ticketPoolInclude: Boolean = false
    $eventInclude: Boolean = false
    $refundsInclude: Boolean = false
    $refundsSkip: Int
    $refundsTake: Int
    $fileInclude: Boolean = false
    $refundsFilters: RefundFilterInput
    $refundsOrderBy: [RefundOrderByInput!]
    $orderInclude: Boolean = false
  ) {
    orders(take: $take, skip: $skip, filters: $filters, orderBy: $orderBy) {
      count
      nodes {
        ...OrderWithRelations
      }
    }
  }
`;
