import { gql } from "@apollo/client";
import { DISCOUNT_CODE_FRAGMENT } from "discountCode/fragments/discountCode";
import { TICKET_POOL_FRAGMENT } from "ticketPool/fragments";
import { PRESALE_PASSWORD_FRAGMENT } from "presalePassword/fragments";
import { VENUE_FRAGMENT } from "venue";
import { IMAGE_FRAGMENT } from "image/fragments";
import { ADDRESS_FRAGMENT } from "address/fragments";
import { SEAT_MAP_FRAGMENT } from "seatMap/fragments";

import { EVENT_FRAGMENT } from "./event";

export const EVENT_WITH_RELATIONS_FRAGMENT = gql`
  ${EVENT_FRAGMENT}
  ${TICKET_POOL_FRAGMENT}
  ${PRESALE_PASSWORD_FRAGMENT}
  ${DISCOUNT_CODE_FRAGMENT}
  ${VENUE_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${SEAT_MAP_FRAGMENT}
  fragment EventWithRelations on Event {
    ...Event
    venue @include(if: $venueInclude) {
      ...Venue
      address {
        ...Address
      }
    }
    image @include(if: $imageInclude) {
      ...Image
    }
    seatMap @include(if: $seatMapInclude) {
      ...SeatMap
      image {
        ...Image
      }
    }
    ticketPools(
      skip: $ticketPoolsSkip
      take: $ticketPoolsTake
      filters: $ticketPoolsFilters
      orderBy: $ticketPoolsOrderBy
    ) @include(if: $ticketPoolsInclude) {
      nodes {
        ...TicketPool
      }
    }
    presalePasswords(
      skip: $presalePasswordsSkip
      take: $presalePasswordsTake
      filters: $presalePasswordsFilters
      orderBy: $presalePasswordsOrderBy
    ) @include(if: $presalePasswordsInclude) {
      nodes {
        ...PresalePassword
      }
    }
    discountCodes(
      skip: $discountCodesSkip
      take: $discountCodesTake
      filters: $discountCodesFilters
      orderBy: $discountCodesOrderBy
    ) @include(if: $discountCodesInclude) {
      nodes {
        ...DiscountCode
      }
    }

    previewId @include(if: $includePreviewId)
    eventOccuranceId @include(if: $includePreviewId)
  }
`;
