import addMonths from "date-fns/addMonths";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import { useState, useMemo } from "react";
import { formatDateMonth } from "utils/date";

import {
  MonthPickerOption,
  UseMonthPickerProps,
  UseMonthPickerReturnValue,
} from "./types";

const useMonthPicker = <IsMulti extends boolean = false>({
  isMulti,
  count = 24,
  firstAsDefaultValue,
}: UseMonthPickerProps<IsMulti> = {}): UseMonthPickerReturnValue<IsMulti> => {
  const [value, onChange] =
    useState<UseMonthPickerReturnValue<IsMulti>["value"]>();

  return useMemo(() => {
    const options: MonthPickerOption[] = Array.from(
      { length: count },
      (_, index) => {
        const date = addMonths(new Date(), index);

        return {
          month: getMonth(date),
          year: getYear(date),
          label: formatDateMonth(date),
        };
      }
    );

    const defaultValue = (
      isMulti ? options.slice(0, 1) : options[0]
    ) as UseMonthPickerReturnValue<IsMulti>["value"];

    return {
      value: value || (firstAsDefaultValue ? defaultValue : undefined),
      onChange,
      isMulti,
      options,
    };
  }, [count, firstAsDefaultValue, isMulti, value]);
};

export default useMonthPicker;
