import { gql } from "@apollo/client";
import { EVENT_FRAGMENT } from "event/fragments/event";

import { DISCOUNT_CODE_FRAGMENT } from "./discountCode";

export const DISCOUNT_CODE_NODE_FRAGMENT = gql`
  ${DISCOUNT_CODE_FRAGMENT}
  ${EVENT_FRAGMENT}
  fragment DiscountCodeNode on DiscountCode {
    ...DiscountCode
    events(
      skip: $eventsSkip
      take: $eventsTake
      filters: $eventsFilters
      orderBy: $eventsOrderBy
    ) {
      count
      nodes {
        ...Event
      }
    }
  }
`;
