const getYoutubeVideoId = (url?: string | null): string | undefined => {
  const { 7: videoId } =
    url?.match(
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    ) || [];

  return videoId?.length === 11 ? videoId : undefined;
};

export default getYoutubeVideoId;
