import styled from "styled-components";

const Inner = styled.div`
  padding-left: ${({ theme }) => theme.sizes.defaultPadding};
  padding-right: ${({ theme }) => theme.sizes.defaultPadding};
  width: 100%;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.sizes.inner};
`;

export { Inner };
