import { LazyLoadImage } from "react-lazy-load-image-component";
import { AspectRatio } from "react-aspect-ratio";

import * as Styled from "./styles";
import { ImageProps } from "./types";

const Image: React.FC<ImageProps> = ({
  src,
  lazy = true,
  alt,
  ratio,
  fit,
  ...rest
}) => {
  const image = (
    <Styled.ImageFigure>
      {lazy ? (
        <LazyLoadImage
          key={src}
          {...{ src, alt, ...rest }}
          placeholder={
            <Styled.ImagePlaceholder>
              {/* TODO */}
              Loading...
            </Styled.ImagePlaceholder>
          }
        />
      ) : (
        src && <img {...{ src, alt, ...rest }} />
      )}
    </Styled.ImageFigure>
  );

  return (
    <Styled.Wrapper {...{ fit }}>
      {ratio ? <AspectRatio {...{ ratio }}>{image}</AspectRatio> : image}
    </Styled.Wrapper>
  );
};

export default Image;
