import {
  LazyQueryHookOptions,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  QueryTuple,
  RefetchQueriesFunction,
  TypedDocumentNode,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import { DocumentNode } from "graphql/language";

export const getOperationFields = (doc: DocumentNode) =>
  doc.definitions.reduce<string[]>((fields, operation) => {
    if (operation.kind !== "OperationDefinition") {
      return fields;
    }

    return operation.selectionSet.selections.reduce((_fields, selection) => {
      if (selection.kind !== "Field") {
        return _fields;
      }

      const value = selection.alias?.value ?? selection.name.value;

      if (!value) {
        return _fields;
      }

      return [..._fields, value];
    }, fields);
  }, []);

export const makeMutationHook =
  <TData = unknown, TVariables extends OperationVariables = OperationVariables>(
    mutation: DocumentNode | TypedDocumentNode<TData, TVariables>
  ): ((
    options?: MutationHookOptions<TData, TVariables> & {
      evictQueries?: DocumentNode[];
    }
  ) => MutationTuple<TData, TVariables>) =>
  (
    options?: MutationHookOptions<TData, TVariables> & {
      evictQueries?: DocumentNode[];
    }
  ) => {
    const { cache } = useApolloClient();
    const { evictQueries, ...restOptions } = options || {};

    return useMutation<TData, TVariables>(mutation, {
      ...restOptions,
      onCompleted(data, clientOptions) {
        evictQueries?.forEach((doc) =>
          getOperationFields(doc).forEach((fieldName: string) =>
            cache.evict({ fieldName })
          )
        );

        return options?.onCompleted?.(data, clientOptions);
      },
    });
  };

export const makeQueryHook =
  <TData = unknown, TVariables extends OperationVariables = OperationVariables>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>
  ): ((
    options?: QueryHookOptions<TData, TVariables>
  ) => QueryResult<TData, TVariables>) =>
  (options?: QueryHookOptions<TData, TVariables>) =>
    useQuery<TData, TVariables>(query, options);

export const makeLazyQueryHook =
  <TData = unknown, TVariables extends OperationVariables = OperationVariables>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>
  ): ((
    options?: LazyQueryHookOptions<TData, TVariables>
  ) => QueryTuple<TData, TVariables>) =>
  (options?: LazyQueryHookOptions<TData, TVariables>) =>
    useLazyQuery<TData, TVariables>(query, options);

export const refetchQueries: RefetchQueriesFunction = (
  queries: DocumentNode[]
) =>
  queries
    .map((query) => getOperationName(query))
    .filter((name): name is string => name !== null);

export const mergePagination = <T extends { nodes: unknown[] }>(
  existing?: T,
  incoming?: T
): T =>
  ({
    ...incoming,
    nodes: [...(existing?.nodes || []), ...(incoming?.nodes || [])],
  } as T);
