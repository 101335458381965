import Badge from "components/atoms/Badge";
import Image from "components/atoms/Image";
import Text from "components/styles/Typography";

import * as Styled from "./styles";
import { EventTeaserProps } from "./types";

const EventTeaser: React.FC<EventTeaserProps> = ({
  ageLimit,
  dateTime,
  name,
  venue,
  href,
  image,
  price,
  presale,
}) => (
  <Styled.EventTeaserBox>
    <Styled.EventTeaserLink to={href} />
    {(presale?.isPresalePasswordsAvailable ||
      presale?.isAvailableNow === false) && <Styled.EventPresaleOverlay />}
    <Image ratio={300 / 200} {...image} lazy={false} fit="contain" />
    <Styled.EventTeaserMain>
      <Styled.EventTeaserAgeLimit>
        <Text variant="typo2">{ageLimit}</Text>
      </Styled.EventTeaserAgeLimit>
      {price && (
        <Styled.EventTeaserBadge>
          <Badge {...price} />
        </Styled.EventTeaserBadge>
      )}
      <Styled.EventTeaserBottom>
        <Text variant="typo4">{dateTime}</Text>
        <Text variant="heading3" as="h3">
          {name}
        </Text>
        <Text variant="typo4">{venue}</Text>
      </Styled.EventTeaserBottom>
    </Styled.EventTeaserMain>
  </Styled.EventTeaserBox>
);

export default EventTeaser;
