import { EventTeaserItem } from "@ticketingplatform/ui/dist/components/organisms/EventsList/types";
import { useEventOccurrencesQuery } from "@ticketingplatform/api/dist/event";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { generatePath } from "react-router-dom";
import {
  formatDateTime,
  getEndOfMonthUTC,
  getStartOfMonthUTC,
} from "utils/date";
import {
  EventStatus,
  SortDirection,
} from "@ticketingplatform/api/dist/__generated__/globalTypes";
import { QueryHookOptions } from "@apollo/client";
import { EventsSectionProps } from "@ticketingplatform/ui/dist/components/sections/EventsSection/types";
import {
  GetEventOccurrences,
  GetEventOccurrencesVariables,
} from "@ticketingplatform/api/dist/event/__generated__/GetEventOccurrences";
import { ROUTE_PATHS } from "setup/routePaths";
import environment from "setup/environment";
import { getPriceToDisplay } from "utils/priceDisplay";

import { getPresalesOptions } from "./utils";

export const useEventList = (
  venueId?: number,
  month?: number,
  year?: number
): Pick<EventsSectionProps, "items" | "loading" | "errorMessage"> => {
  const intl = useIntl();

  const queryOptions = useMemo<
    QueryHookOptions<GetEventOccurrences, GetEventOccurrencesVariables>
  >(() => {
    const skip =
      month === undefined || year === undefined || venueId === undefined;

    if (skip) {
      return { skip };
    }

    return {
      skip,
      variables: {
        eventInclude: true,
        venueInclude: true,
        imageInclude: true,
        seatMapInclude: false,
        discountCodesInclude: false,
        ticketPoolsInclude: true,
        ticketPoolsOrderBy: [{ price: SortDirection.asc }],
        ticketPoolsTake: -1,
        presalePasswordsInclude: false,
        presalePasswordsTake: -1,
        start: getStartOfMonthUTC(month, year),
        end: getEndOfMonthUTC(month, year),
        filters: {
          status: { equals: EventStatus.PUBLISHED },
          venueId: { equals: venueId },
        },
      },
    };
  }, [month, venueId, year]);

  const { data, loading, error } = useEventOccurrencesQuery(queryOptions);

  return useMemo(
    () => ({
      loading,
      errorMessage:
        error &&
        intl.formatMessage({ defaultMessage: "Failed to load Events" }),
      items:
        data?.eventOccurrences?.nodes.map<EventTeaserItem>(
          ({
            id,
            date,
            event: {
              name,
              timezone,
              ageLimit,
              ticketPools,
              presalePasswords,
              venue,
              image,
            },
          }) => {
            const [cheapestTicketPool] = ticketPools?.nodes || [];

            const displayPrice = getPriceToDisplay(
              cheapestTicketPool,
              venue?.priceDisplay
            );

            return {
              key: id,
              name,
              href: generatePath(ROUTE_PATHS.EVENT_DETAILS, {
                eventOccurrenceId: id,
              }),
              ageLimit:
                ageLimit > 0
                  ? intl.formatMessage(
                      { defaultMessage: "Ages {ageLimit} & Over" },
                      { ageLimit }
                    )
                  : undefined,
              dateTime: formatDateTime(date, timezone),
              venue: [
                venue?.name,
                venue?.address?.city && ` - ${venue?.address?.city}`,
                venue?.address?.provinceCode &&
                  `, ${venue?.address?.provinceCode}`,
              ]
                .filter(Boolean)
                .join(""),
              price:
                displayPrice === undefined || displayPrice == null
                  ? undefined
                  : {
                      label: `${
                        displayPrice === 0
                          ? intl.formatMessage({ defaultMessage: "Free" })
                          : intl.formatNumber(displayPrice, {
                              style: "currency",
                              currency: environment.CURRENCY,
                            })
                      } +`,
                    },
              image: image?.url
                ? {
                    src: image.url,
                    alt: intl.formatMessage(
                      { defaultMessage: "{name} poster" },
                      { name }
                    ),
                  }
                : undefined,
              presale: getPresalesOptions(
                presalePasswords?.nodes,
                ticketPools?.nodes,
                date
              ),
            };
          }
        ) || [],
    }),
    [data?.eventOccurrences?.nodes, error, intl, loading]
  );
};
