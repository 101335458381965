import { useQuantityPickerValue } from "./hooks";
import * as Styled from "./styles";
import { QuantityPickerProps } from "./types";

const QuantityPicker: React.FC<QuantityPickerProps> = (props) => {
  const { input, decrementButton, incrementButton } =
    useQuantityPickerValue(props);

  return (
    <Styled.QuantityPicker>
      <Styled.QuantityPickerButtonLeft
        aria-label="Subtract 1"
        type="button"
        {...decrementButton}
      >
        -
      </Styled.QuantityPickerButtonLeft>
      <Styled.QuantityPickerValue type="number" {...input} />
      <Styled.QuantityPickerButtonRight
        aria-label="Add 1"
        type="button"
        {...incrementButton}
      >
        +
      </Styled.QuantityPickerButtonRight>
    </Styled.QuantityPicker>
  );
};

export default QuantityPicker;
