import EventListPage from "pages/EventList";
import EventDetailsPage from "pages/EventDetails";
import { Outlet, createHashRouter } from "react-router-dom";
import { ROUTE_PATHS } from "setup/routePaths";
import OrderProvider from "providers/OrderProvider";
import CartBillingPage from "pages/CartBilling";
import CheckoutPage from "pages/Checkout";
import SuccessPage from "pages/Success";
import ErrorPage from "pages/Error";

export const router = createHashRouter([
  {
    element: (
      <OrderProvider>
        <Outlet />
      </OrderProvider>
    ),
    children: [
      {
        path: ROUTE_PATHS.EVENT_LIST,
        element: <EventListPage />,
      },
      {
        path: ROUTE_PATHS.EVENT_DETAILS,
        element: <EventDetailsPage />,
      },
      {
        path: ROUTE_PATHS.EVENT_BILLING,
        element: <CartBillingPage />,
      },
      {
        path: ROUTE_PATHS.EVENT_CHECKOUT,
        element: <CheckoutPage />,
      },
      {
        path: ROUTE_PATHS.EVENT_SUCCESS,
        element: <SuccessPage />,
      },
      {
        path: ROUTE_PATHS.EVENT_ERROR,
        element: <ErrorPage />,
      },
    ],
  },
]);
