import styled, { css } from "styled-components";
import media from "theme/config/media";

import { StyledInfoBarProps } from "./types";

export const InfoBar = styled.div<StyledInfoBarProps>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.dodgerBlue};
  color: ${({ theme }) => theme.colors.white};
  padding: 0.75rem;
  text-align: center;
  z-index: 1;

  ${({ $isFixed }) =>
    $isFixed &&
    css`
      position: fixed;
      bottom: ${({ theme }) => theme.sizes.summaryBarHeight};
      left: 0;
      right: 0;
    `}

  @media ${media.phone} {
    padding-left: ${({ theme }) => theme.sizes.defaultPadding};
    padding-right: ${({ theme }) => theme.sizes.defaultPadding};
  }
`;
