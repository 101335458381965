import { PropsWithChildren } from "react";

import * as Styled from "./styles";

const Wysiwyg: React.FC<PropsWithChildren> = ({ children }) =>
  typeof children === "string" ? (
    <Styled.WysiwygParagraph>{children}</Styled.WysiwygParagraph>
  ) : (
    <Styled.WysiwygContainer>{children}</Styled.WysiwygContainer>
  );

export default Wysiwyg;
