import React, { PropsWithChildren } from "react";

import * as Styled from "./styles";
import { ButtonLinkProps } from "./types";

const ButtonLink = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<ButtonLinkProps>
>(({ children, $mode = "primary", $size = "medium", href, ...rest }, ref) => (
  <Styled.ButtonLink {...{ $mode, $size, ref, ...rest }} to={href}>
    {children}
  </Styled.ButtonLink>
));

export default ButtonLink;
