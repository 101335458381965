import { gql } from "@apollo/client";
import { TICKET_WITH_RELATIONS_FRAGMENT } from "ticket/fragments/ticketWithRelations";
import { DISCOUNT_CODE_FRAGMENT } from "discountCode";
import { TICKET_POOL_FRAGMENT } from "ticketPool";
import { EVENT_FRAGMENT } from "event/fragments/event";
import { REFUND_FRAGMENT } from "refund/fragments";

import { ORDER_FRAGMENT } from "./order";

export const ORDER_WITH_RELATIONS_FRAGMENT = gql`
  ${ORDER_FRAGMENT}
  ${TICKET_WITH_RELATIONS_FRAGMENT}
  ${DISCOUNT_CODE_FRAGMENT}
  ${TICKET_POOL_FRAGMENT}
  ${EVENT_FRAGMENT}
  ${REFUND_FRAGMENT}
  fragment OrderWithRelations on Order {
    ...Order
    tickets(
      skip: $ticketsSkip
      take: $ticketsTake
      filters: $ticketsFilters
      orderBy: $ticketsOrderBy
    ) @include(if: $ticketsInclude) {
      count
      nodes {
        ...TicketWithRelations
      }
    }
    refunds(
      skip: $refundsSkip
      take: $refundsTake
      filters: $refundsFilters
      orderBy: $refundsOrderBy
    ) @include(if: $refundsInclude) {
      count
      nodes {
        ...Refund
      }
    }
    discountCode @include(if: $discountCodeInclude) {
      ...DiscountCode
    }
  }
`;
