import styled from "styled-components";
import media from "theme/config/media";

export const EventsList = styled.ul`
  display: grid;
  grid-gap: 1rem;

  @media ${media.phone} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${media.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${media.desktop} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const EventsListItem = styled.li``;
