import DiscountCode from "components/molecules/DiscountCodeForm";
import { Form } from "react-final-form";

import SummaryBar from "../SummaryBar";

import * as Styled from "./styles";
import { CheckoutFormProps } from "./types";

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  ticketsItems,
  ticketsCostsItems,
  totalLabel,
  total,
  quantityLabel,
  discountCodeForm,
  discountCodeValue,
  discountCodeLabel,
  discountCode,
  summaryBar,
  submitLabel,
  ...formProps
}) => (
  <Form
    {...formProps}
    render={({ handleSubmit, submitting, submitError }) => (
      <Styled.CheckoutForm onSubmit={handleSubmit}>
        <Styled.CheckoutTicketsTypes>
          {ticketsItems.map(({ key, ticketType, quantity }) => (
            <Styled.CheckoutTicketsTypesItem variant="typo2" {...{ key }}>
              {ticketType}
              <span>
                {quantityLabel}: {quantity}
              </span>
            </Styled.CheckoutTicketsTypesItem>
          ))}
        </Styled.CheckoutTicketsTypes>
        {ticketsCostsItems.map(({ key, name, value }) => (
          <Styled.CheckoutTicketsDetailsText variant="typo4" {...{ key }}>
            {name}: <span>{value}</span>
          </Styled.CheckoutTicketsDetailsText>
        ))}
        <DiscountCode {...discountCodeForm} />
        {discountCode && discountCodeValue && (
          <Styled.CheckoutTicketsDetailsText variant="typo2">
            {discountCodeLabel} ({discountCode}){" "}
            <span>{discountCodeValue}</span>
          </Styled.CheckoutTicketsDetailsText>
        )}
        <Styled.CheckoutTicketsDetailsText variant="typo2">
          {totalLabel}:<span>{total}</span>
        </Styled.CheckoutTicketsDetailsText>
        {submitError && (
          <Styled.CheckoutTicketsDetailsText variant="typo1">
            Error:<span>{submitError}</span>
          </Styled.CheckoutTicketsDetailsText>
        )}
        <SummaryBar
          {...summaryBar}
          buttonType="submit"
          buttonLabel={submitLabel || summaryBar.buttonLabel}
          disabled={submitting}
        />
      </Styled.CheckoutForm>
    )}
  />
);

export default CheckoutForm;
