import ReactPhoneNumberInput from "react-phone-number-input/input";
import noop from "utils/noop";

import Input from "../Input";

import { PhoneNumberInputProps, PhoneNumberInputWrapper } from "./types";

const Wrapper = ReactPhoneNumberInput as PhoneNumberInputWrapper;

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = (props) => (
  <Wrapper
    defaultCountry="US"
    inputComponent={Input}
    onChange={noop}
    {...props}
  />
);

export default PhoneNumberInput;
