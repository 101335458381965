export const INPUT_VARIANTS = {
  primary: {
    backgroundColor: "transparent",
    color: "white",
    padding: "0.25rem 0.75rem",
  },
  secondary: {
    borderColor: "blueDianne",
    backgroundColor: "white",
    color: "blueDianne",
    borderRadius: ".375rem",
    fontWeight: "semiBold",
    padding: ".25rem .375rem",
    "&:placeholder": {
      color: "rgba(30, 84, 86, 0.4)",
    },
  },
  tertiary: {
    backgroundColor: "transparent",
    color: "black",
    padding: ".25rem .375rem",
    borderColor: "darkGray",
    borderWidth: "0 0 1px 0",
    "&::placeholder": {
      color: "gray",
    },
  },
};
