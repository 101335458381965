import { NavItem } from "@ticketingplatform/ui/dist/components/organisms/Header/partials/Nav/types";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { ROUTE_PATHS } from "setup/routePaths";
import {
  generatePath,
  matchPath,
  useLocation,
  useParams,
} from "react-router-dom";

const useNavItems = (): NavItem[] => {
  const intl = useIntl();
  const location = useLocation();
  const { eventOccurrenceId = "" } = useParams();

  return useMemo<NavItem[]>(() => {
    const navItems: NavItem[] = [
      {
        key: ROUTE_PATHS.EVENT_LIST,
        label: intl.formatMessage({
          defaultMessage: "Events",
          description: "Nav item label",
        }),
      },
      {
        key: ROUTE_PATHS.EVENT_DETAILS,
        label: intl.formatMessage({
          defaultMessage: "Tickets",
          description: "Nav item label",
        }),
      },
      {
        key: ROUTE_PATHS.EVENT_BILLING,
        label: intl.formatMessage({
          defaultMessage: "Info",
          description: "Nav item label",
        }),
      },
      {
        key: ROUTE_PATHS.EVENT_CHECKOUT,
        label: intl.formatMessage({
          defaultMessage: "Checkout",
          description: "Nav item label",
        }),
      },
    ];

    const activeIndex = navItems.findIndex(({ key }) =>
      matchPath(key, location.pathname)
    );

    return navItems.map(
      ({ key, href, $isActive, $isDisabled, label, ...rest }, index) => ({
        label,
        key,
        href: href || generatePath(key, { eventOccurrenceId }),
        $isActive: $isActive || index === activeIndex,
        $isDisabled: $isDisabled || index > activeIndex,
        ...rest,
      })
    );
  }, [eventOccurrenceId, intl, location.pathname]);
};

export default useNavItems;
