import React, { PropsWithChildren } from "react";

import * as Styled from "./styles";
import { ButtonProps } from "./types";
import ButtonLink from "./ButtonLink";

const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  children,
  type = "button",
  $mode = "primary",
  $size = "medium",
  ...rest
}) => (
  <Styled.Button {...{ type, $mode, $size, ...rest }}>{children}</Styled.Button>
);

export { Button as default, ButtonLink };
