import { forwardRef } from "react";

import FieldWrapper from "../FieldWrapper";

import { InputProps } from "./types";
import * as Styled from "./styles";

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ placeholder, success, error, variant = "primary", ...rest }, ref) => (
    <FieldWrapper
      {...{ error }}
      controller={
        <Styled.Input
          {...{
            placeholder,
            error,
            success,
            ref,
            variant,
            ...rest,
          }}
        />
      }
    />
  )
);

export default Input;
