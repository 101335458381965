import styled from "styled-components";

export const TooltipText = styled.div`
  position: absolute;
  top: -50%;
  left: calc(100% + 0.5rem);
  padding: 0.375rem;
  z-index: 1;
  width: 6rem;
  opacity: 0;
  font-size: 0.75rem;
  visibility: hidden;
  text-align: center;
  border-radius: 0.375rem;
  background-color: ${({ theme }) => theme.colors.gray};
  transition: ${({ theme }) => theme.transition};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -0.3125rem;
    border-width: 0.3125rem;
    border-style: solid;
    border-color: transparent ${({ theme }) => theme.colors.gray} transparent
      transparent;
  }
`;

export const Tooltip = styled.div`
  position: relative;
  display: inline-flex;
  cursor: pointer;

  &:hover ${TooltipText} {
    visibility: visible;
    opacity: 1;
  }
`;
