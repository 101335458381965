import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { mergePagination } from "utils/apolloUtils";

const baseUrl = process.env.REACT_APP_API_APPSYNC_URL;

export const makeApolloClient = (customLinks: ApolloLink[] = []) =>
  new ApolloClient({
    link: ApolloLink.from([
      ...customLinks,
      createHttpLink({
        uri: baseUrl,
      }),
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            tickets: {
              keyArgs: [
                "take",
                "filters",
                "orderBy",
                "ticketPoolInclude",
                "eventInclude",
                "fileInclude",
                "orderInclude",
              ],
              merge: mergePagination,
            },
          },
        },
      },
    }),
  });
