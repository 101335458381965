import CheckoutForm from "components/organisms/CheckoutForm";

import Checkout from "..";

import { CheckoutSectionProps } from "./types";

const CheckoutSection: React.FC<CheckoutSectionProps> = ({
  eventHeading,
  ...rest
}) => (
  <Checkout {...{ eventHeading }}>
    <CheckoutForm {...rest} />
  </Checkout>
);

export default CheckoutSection;
