import Header from "@ticketingplatform/ui/dist/components/organisms/Header";
import useNavItems from "hooks/useNavItems";
import CheckoutSection from "@ticketingplatform/ui/dist/components/sections/Checkout/CheckoutSection";
import { useIntl } from "react-intl";
import useSessionInfoBar from "hooks/useSessionInfoBar";
import useSummaryBar from "hooks/useSummaryBar";
import useEventHeading from "hooks/useEventHeading";
import SessionInfoBar from "components/SessionInfoBar";

import { useCheckout, useDiscountCodeForm } from "./hooks";

const CheckoutPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const navItems = useNavItems();
  const eventHeading = useEventHeading();
  const summaryBar = useSummaryBar();
  const sessionInfoBar = useSessionInfoBar();
  const discountCodeForm = useDiscountCodeForm();
  const checkout = useCheckout();

  return (
    <>
      <Header href="/" items={navItems} />
      <CheckoutSection
        quantityLabel={formatMessage({
          defaultMessage: "QTY",
          description: "Quantity label",
        })}
        totalLabel={formatMessage({
          defaultMessage: "Total",
          description: "Total label",
        })}
        discountCodeLabel={formatMessage({
          defaultMessage: "Discount Code",
          description: "Discount code label",
        })}
        submitLabel={formatMessage({
          defaultMessage: "Payment",
          description: "Summary bar button label label",
        })}
        {...{
          eventHeading,
          summaryBar,
          discountCodeForm,
        }}
        {...checkout}
      />
      {sessionInfoBar && <SessionInfoBar {...sessionInfoBar} />}
    </>
  );
};

export default CheckoutPage;
