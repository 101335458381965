import { gql } from "@apollo/client";

import { ORGANIZATION_FRAGMENT } from "./fragments";

export const GET_ORGANIZATIONS = gql`
  ${ORGANIZATION_FRAGMENT}
  query GetOrganizations(
    $take: Int
    $skip: Int
    $filters: OrganizationsFilterInput
    $orderBy: [OrganizationOrderByInput!]
  ) {
    organizations(
      take: $take
      skip: $skip
      filters: $filters
      orderBy: $orderBy
    ) {
      nodes {
        ...Organization
      }
      count
    }
  }
`;

export const GET_ORGANIZATION = gql`
  ${ORGANIZATION_FRAGMENT}
  query GetOrganization($id: Int!) {
    organization(id: $id) {
      ...Organization
    }
  }
`;
