import QuantityPicker from "components/atoms/QuantityPicker";
import Text from "components/styles/Typography";
import Tooltip from "components/atoms/Tooltip";
import FieldWrapper from "components/atoms/Fields/FieldWrapper";

import EyeInvisible from "../../../assets/svg/eye-invisible.svg";

import * as Styled from "./styles";
import { TicketsListProps } from "./types";
import { useTicketsListValue } from "./hooks";

const TicketsList: React.FC<TicketsListProps> = ({
  onSaleLabel,
  disabled,
  variant,
  ...props
}) => {
  const { items, handleItemChange } = useTicketsListValue(props);

  return (
    <Styled.TicketsList>
      {items.map(
        (
          {
            key,
            name,
            price,
            dateTime,
            maxValue,
            value,
            tooltipLabel,
            feeLabel,
            error,
            disabled: itemDisabled,
            isScannerOnly,
          },
          index
        ) => (
          <Styled.TicketsListItem {...{ key }}>
            <Styled.TicketsListDetails>
              <Text variant="typo2" mb="0.25rem" as="div">
                <Styled.TicketsListHead>
                  <span>{name}</span>
                  {isScannerOnly && (
                    <EyeInvisible style={{ height: 16, width: 16 }} />
                  )}
                </Styled.TicketsListHead>
                {tooltipLabel && (
                  <Styled.TicketsListTooltip>
                    <Tooltip label={tooltipLabel} />
                  </Styled.TicketsListTooltip>
                )}
              </Text>
              <Text variant="typo4">
                {price} {feeLabel}
              </Text>
              {dateTime && (
                <Text variant="typo4" mt="0.25rem">
                  {onSaleLabel}: {dateTime}
                </Text>
              )}
            </Styled.TicketsListDetails>
            <Styled.TicketsListQuantityPicker>
              <FieldWrapper
                {...{ error }}
                controller={
                  <QuantityPicker
                    {...{
                      maxValue,
                      value,
                      variant,
                    }}
                    onChange={handleItemChange(index)}
                    disabled={disabled || itemDisabled}
                  />
                }
              />
            </Styled.TicketsListQuantityPicker>
          </Styled.TicketsListItem>
        )
      )}
    </Styled.TicketsList>
  );
};

export default TicketsList;
