import {
  GetEventOccurrences_eventOccurrences_nodes_event_presalePasswords_nodes,
  GetEventOccurrences_eventOccurrences_nodes_event_ticketPools_nodes,
} from "@ticketingplatform/api/dist/event/__generated__/GetEventOccurrences";
import { getSaleDate, zonedTimeToLocal } from "utils/date";

export const getPresalesPasswords = (
  presalePasswords:
    | GetEventOccurrences_eventOccurrences_nodes_event_presalePasswords_nodes[]
    | undefined,
  timezone?: string
) => {
  if (!presalePasswords || !presalePasswords.length) {
    return undefined;
  }
  const currentDate = new Date();

  const presalePasswordsAvailable = presalePasswords
    .map(({ password, expireAt }) => {
      const localTime = zonedTimeToLocal(
        new Date(expireAt.toString()),
        timezone
      );

      if (currentDate <= localTime) {
        return password;
      }

      return null;
    })
    .filter(Boolean)
    .map((i) => `${i}`);

  return presalePasswordsAvailable;
};

export const checkAvailableNow = (
  ticketPools?: GetEventOccurrences_eventOccurrences_nodes_event_ticketPools_nodes[],
  date?: AWSDateTime
) => {
  if (!ticketPools || !ticketPools.length || !date) {
    return false;
  }

  let earliestTicketsAvailable = new Date();
  let ticketsAvailableNow = false;

  ticketPools.forEach(({ availableBeforeDays, availableBeforeTime }) => {
    if (!availableBeforeDays || !availableBeforeTime) {
      ticketsAvailableNow = true;

      return;
    }

    const saleDate = getSaleDate(
      new Date(date.toString()),
      availableBeforeDays,
      availableBeforeTime
    );

    if (saleDate < earliestTicketsAvailable) {
      earliestTicketsAvailable = saleDate;
    }
  });

  const currentDate = new Date();

  return ticketsAvailableNow || currentDate > earliestTicketsAvailable;
};
