import ReactLoadingSkeleton from "react-loading-skeleton";
import styled from "styled-components";
import reactLoadingSkeletonCSS from "react-loading-skeleton/dist/skeleton.css";

import { SkeletonProps } from "./types";

export const Skeleton = styled(ReactLoadingSkeleton).attrs<SkeletonProps>(
  ({ theme, variant, baseColor, highlightColor }) =>
    variant === "light"
      ? {
          baseColor: baseColor || theme.colors.gray,
          highlightColor: highlightColor || theme.colors.darkGray,
        }
      : {
          baseColor: baseColor || theme.colors.darkGray,
          highlightColor: highlightColor || theme.colors.gray,
        }
)`
  ${reactLoadingSkeletonCSS}

  .react-loading-skeleton {
    z-index: auto;
  }
`;
