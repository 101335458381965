import { FieldValidationFunctionSync } from "@lemoncode/fonk";
import { isValidPhoneNumber } from "react-phone-number-input";

const VALIDATOR_TYPE = "IS_PHONE_NUMBER";

const isDefined = (value: unknown) =>
  value !== undefined && value !== null && value !== "";

const validator: FieldValidationFunctionSync = ({
  value,
  message = "Please enter a valid phone number.",
}) => {
  const succeeded = !isDefined(value) || isValidPhoneNumber(value);

  return {
    succeeded,
    message: succeeded ? "" : (message as string),
    type: VALIDATOR_TYPE,
  };
};

const phoneNumberValidator = { validator };

export default phoneNumberValidator;
