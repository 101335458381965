import { useContext, useEffect, useMemo, useState } from "react";
import {
  ORDER_BY_TOKEN_QUERY,
  useOrderCreateMutation,
  useOrderUpdateMutation,
  useOrderByTokenQuery,
} from "@ticketingplatform/api/dist/order";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { LOCAL_STORAGE_ORDER_KEY_PREFIX } from "setup/consts";
import { ApolloError, useApolloClient } from "@apollo/client";
import {
  OrderByToken,
  OrderByTokenVariables,
} from "@ticketingplatform/api/dist/order/__generated__/OrderByToken";
import { useParams } from "react-router-dom";
import { PaymentType } from "@ticketingplatform/api/dist/__generated__/globalTypes";

import { OrderValue } from "./types";
import { OrderContext } from "./context";

const baseOrderVariables: Partial<OrderByTokenVariables> = {
  ticketsInclude: true,
  ticketsTake: -1,
  discountCodeInclude: true,
};

export const useOrderContextValue = (): OrderValue => {
  const { cache } = useApolloClient();
  const { eventOccurrenceId } = useParams();
  const [errorMessage, setErrorMessage] = useState<ApolloError | undefined>();
  const [orderToken, setOrderToken, removeOrderToken] = useLocalStorage<string>(
    `${LOCAL_STORAGE_ORDER_KEY_PREFIX}_${eventOccurrenceId}`,
    undefined,
    { raw: true }
  );

  const {
    data,
    loading: readLoading,
    error: readError,
  } = useOrderByTokenQuery(
    orderToken
      ? { variables: { ...baseOrderVariables, token: orderToken } }
      : { skip: true }
  );

  const [
    orderCreateMutation,
    { loading: createLoading, error: createError, reset: resetCreate },
  ] = useOrderCreateMutation({
    onCompleted({ orderCreate: newOrder }) {
      if (!newOrder?.token) {
        return;
      }

      cache.writeQuery<OrderByToken, OrderByTokenVariables>({
        query: ORDER_BY_TOKEN_QUERY,
        variables: {
          ...baseOrderVariables,
          token: newOrder.token,
        },
        data: {
          orderByToken: newOrder,
        },
      });

      setOrderToken(newOrder.token);
    },
  });

  const [
    orderUpdateMutation,
    { loading: updateLoading, error: updateError, reset: resetUpdate },
  ] = useOrderUpdateMutation();

  useEffect(() => {
    resetCreate();
    resetUpdate();
  }, [resetCreate, resetUpdate, eventOccurrenceId]);

  // Eliminate the blink when we have an error and update the value but for invalid one
  useEffect(() => {
    const isLoading = readLoading || createLoading || updateLoading;

    if (!isLoading) {
      setErrorMessage(readError || createError || updateError);
    }
  }, [
    createError,
    createLoading,
    readError,
    readLoading,
    updateError,
    updateLoading,
  ]);

  return useMemo<OrderValue>(
    () => ({
      loading: readLoading || createLoading || updateLoading,
      error: errorMessage,
      order: data?.orderByToken || undefined,
      async setOrder(input) {
        if (orderToken) {
          const updatedOrder = await orderUpdateMutation({
            variables: {
              ...baseOrderVariables,
              token: orderToken,
              input,
            },
          });

          return updatedOrder.data?.orderUpdate || undefined;
        }

        const createdOrder = await orderCreateMutation({
          variables: {
            ...baseOrderVariables,
            input: {
              paymentType: PaymentType.STRIPE_CHECKOUT,
              ...input,
            },
          },
        });

        return createdOrder.data?.orderCreate || undefined;
      },
      async resetOrder() {
        await orderCreateMutation({
          variables: {
            ...baseOrderVariables,
            input: {
              paymentType: PaymentType.STRIPE_CHECKOUT,
            },
          },
        });
      },
      removeOrder() {
        removeOrderToken();
      },
    }),
    [
      createLoading,
      data?.orderByToken,
      errorMessage,
      orderCreateMutation,
      orderToken,
      orderUpdateMutation,
      readLoading,
      removeOrderToken,
      updateLoading,
    ]
  );
};

export const useOrderContext = (): OrderValue => {
  const orderContextValue = useContext(OrderContext);

  if (!orderContextValue) {
    throw new Error(
      "Make sure you are using `useOrderContext` hook inside `OrderProvider` provider"
    );
  }

  return orderContextValue;
};
