import { Form } from "react-final-form";
import CheckboxField from "components/atoms/Fields/Checkbox/CheckboxField";
import InputField from "components/atoms/Fields/Input/InputField";
import PhoneNumberInputField from "components/atoms/Fields/PhoneNumberInput/PhoneNumberInputField";

import SummaryBar from "../SummaryBar";

import * as Styled from "./styles";
import { CartBillingFormProps } from "./types";

const CartBillingForm: React.FC<CartBillingFormProps> = ({
  firstNamePlaceholderLabel,
  lastNamePlaceholderLabel,
  emailPlaceholderLabel,
  confirmEmailPlaceholderLabel,
  phoneNumberPlaceholderLabel,
  notesPlaceholderLabel,
  newsletterCheckboxLabel,
  termsCheckboxLabel,
  submitLabel,
  summaryBar,
  ...formProps
}) => (
  <Form
    {...formProps}
    render={({ handleSubmit, submitting }) => (
      <Styled.CartBillingForm onSubmit={handleSubmit}>
        <InputField name="firstName" placeholder={firstNamePlaceholderLabel} />
        <InputField name="lastName" placeholder={lastNamePlaceholderLabel} />
        <InputField name="email" placeholder={emailPlaceholderLabel} />
        <InputField
          name="confirmEmail"
          placeholder={confirmEmailPlaceholderLabel}
        />
        <PhoneNumberInputField
          name="phoneNumber"
          placeholder={phoneNumberPlaceholderLabel}
        />
        <InputField name="customerNotes" placeholder={notesPlaceholderLabel} />
        <Styled.CartBillingFormAgreements>
          <CheckboxField
            name="newsletterAgree"
            label={newsletterCheckboxLabel}
          />
          <CheckboxField name="termsAgree" label={termsCheckboxLabel} />
        </Styled.CartBillingFormAgreements>
        <SummaryBar
          buttonType="submit"
          {...summaryBar}
          disabled={submitting}
          buttonLabel={submitLabel || summaryBar.buttonLabel}
        />
      </Styled.CartBillingForm>
    )}
  />
);

export default CartBillingForm;
